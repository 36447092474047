import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';

import withModal from '../../hocs/withModal';
import {
  productContent,
  productInnerWrapper,
  productOverlay,
} from '../ProductAdjustModal/ProductAdjustModal.styles';
import OrderFilters from '../OrderFilters';

const FiltersModal = ({
  visible,
  onCloseModal,
  onFinish,
  availableFilters,
  appliedFilters,
}) => {
  const [selectedFilters, setSelectedFilters] = useState(appliedFilters);

  const handleFilterSelect = (newFilter) => {
    setSelectedFilters((prev) => prev.concat(newFilter));
  };

  const handleFilterRemove = (filter) =>
    setSelectedFilters((prev) =>
      prev.filter(
        ({ menuProductGroupId }) =>
          filter.menuProductGroupId !== menuProductGroupId,
      ),
    );

  const handleClearFilters = () => setSelectedFilters([]);

  const handleFinishFiltersChoice = () => {
    onFinish(selectedFilters);
  };

  return (
    <Modal
      visible={visible}
      styles={{
        innerWrapper: productInnerWrapper,
        content: productContent,
        overlay: productOverlay,
      }}
      keepMounted
    >
      <OrderFilters
        filters={availableFilters}
        selectedFilters={selectedFilters}
        onSelect={handleFilterSelect}
        onRemove={handleFilterRemove}
        onClose={onCloseModal}
        onFinish={handleFinishFiltersChoice}
        onClearFilters={handleClearFilters}
      />
    </Modal>
  );
};

FiltersModal.defaultProps = {
  visible: false,
};

FiltersModal.propTypes = {
  visible: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  availableFilters: PropTypes.array.isRequired,
  appliedFilters: PropTypes.array.isRequired,
};

export default withModal((props) => ({
  visible: props.visible,
}))(FiltersModal);

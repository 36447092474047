import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../components/ButtonBit';
import dayjs from '../../utils/dayjs';

import {
  tabWrapper,
  tabTitleWrapper,
  tabTitle,
  orderButtonWrapper,
  scheduleButtonsRow,
  radioBoxContent,
  radioBoxDescription,
  radioBoxTitle,
  radioBoxButton,
} from './OrderReviewPage.styles';

const OrderReviewSchedule = ({
  deliverySchedule,
  onOrderSchedule,
  onClearSchedule,
}) => {
  const { t } = useTranslation();

  return (
    <div css={tabWrapper}>
      <div css={tabTitleWrapper}>
        <span css={tabTitle}>{t('orderReview:orderSchedule')}</span>
      </div>
      {deliverySchedule?.start && (
        <div css={radioBoxContent}>
          <span css={radioBoxTitle}>{t('orderReview:orderScheduledTo')}</span>
          <span css={radioBoxDescription}>
            {`${dayjs(deliverySchedule?.start).format('HH:mm')} - ${dayjs(
              deliverySchedule?.end,
            ).format('HH:mm, DD/MM/YYYY, dddd')}`}
          </span>
        </div>
      )}
      <div css={deliverySchedule?.start && scheduleButtonsRow}>
        <div css={orderButtonWrapper}>
          <Button
            onClick={onOrderSchedule}
            color="primary"
            styles={radioBoxButton}
          >
            {t(
              deliverySchedule?.start
                ? 'orderReview:alterScheduleDelivery'
                : 'orderReview:scheduleDelivery',
            )}
          </Button>
        </div>
        {deliverySchedule?.start && (
          <div css={orderButtonWrapper}>
            <Button
              onClick={onClearSchedule}
              variant="outlined"
              color="error"
              styles={radioBoxButton}
            >
              {t('orderReview:removeSchedule')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

OrderReviewSchedule.defaultProps = {
  deliverySchedule: null,
};

OrderReviewSchedule.propTypes = {
  deliverySchedule: PropTypes.object,
  onOrderSchedule: PropTypes.func.isRequired,
  onClearSchedule: PropTypes.func.isRequired,
};

export default OrderReviewSchedule;

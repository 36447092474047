import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Modal from '../../components/Modal';

import {
  productContent,
  productInnerWrapper,
  productOverlay,
} from '../../components/ProductAdjustModal/ProductAdjustModal.styles';
import ShippingTypeConfirmation from '../../components/ShippingTypeConfirmation';
import OrderShippingTypeSelect from '../../components/OrderShippingTypeSelect';
import withModal from '../../hocs/withModal';
import { shippingTypes } from '../../utils/constants';

const mapStateToProps = ({
  tenantEyepass: { qrCodeOnly, deliveryDisabled },
  tag,
}) => ({
  qrCodeOnly,
  deliveryDisabled,
  tag,
});

const OrderShippingTypeSelectModal = ({
  visible,
  onCloseModal,
  selectedShippingType,
  onShippingTypeSelect,
  isTagOnlyDelivery,
  qrCodeOnly,
  deliveryDisabled,
  tag,
}) => {
  const [newSelectedShippingType, setNewSelectedShippingType] = useState(null);
  const [shippingTypeConfirmationMode, setShippingTypeConfirmationMode] =
    useState(false);

  const availableShippingTypes = useMemo(() => {
    if (isTagOnlyDelivery || qrCodeOnly || tag.isTagOnlyDelivery) {
      return [shippingTypes.TAG];
    }

    if (deliveryDisabled) {
      return [shippingTypes.TAKEOUT];
    }

    const shippingTypesArray = [];

    if (tag.tag) {
      shippingTypesArray.push(shippingTypes.TAG);
    }

    return shippingTypesArray.concat([
      shippingTypes.TAKEOUT,
      shippingTypes.ADDRESS,
    ]);
  }, []);

  const handleShippingTypeChange = (newShippingType) => {
    if (newShippingType && newShippingType !== selectedShippingType) {
      setNewSelectedShippingType(newShippingType);
      setShippingTypeConfirmationMode(true);
    } else {
      onCloseModal();
    }
  };

  const handleShippingTypeChangeConfirmation = () => {
    onShippingTypeSelect(newSelectedShippingType);
  };

  return (
    <Modal
      visible={visible}
      styles={{
        innerWrapper: productInnerWrapper,
        content: productContent,
        overlay: productOverlay,
      }}
      keepMounted
    >
      {shippingTypeConfirmationMode ? (
        <ShippingTypeConfirmation
          onCancel={onCloseModal}
          onConfirm={handleShippingTypeChangeConfirmation}
        />
      ) : (
        <OrderShippingTypeSelect
          disabled
          selectedShippingType={selectedShippingType}
          shippingTypeOptions={availableShippingTypes}
          onSelect={handleShippingTypeChange}
          onClose={onCloseModal}
        />
      )}
    </Modal>
  );
};

OrderShippingTypeSelectModal.defaultProps = {
  selectedShippingType: null,
  visible: false,
  isTagOnlyDelivery: false,
};

OrderShippingTypeSelectModal.propTypes = {
  selectedShippingType: PropTypes.string,
  visible: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
  onShippingTypeSelect: PropTypes.func.isRequired,
  isTagOnlyDelivery: PropTypes.bool,
  qrCodeOnly: PropTypes.bool.isRequired,
  deliveryDisabled: PropTypes.bool.isRequired,
  tag: PropTypes.object.isRequired,
};

const enhance = compose(
  withModal((props) => ({
    visible: props.visible,
  })),
  connect(mapStateToProps),
);

export default enhance(OrderShippingTypeSelectModal);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import Button from '../ButtonBit';

import http from '../../utils/http';
import showToast from '../../utils/showToast';
import { adaptDocument, adaptPhone, stripMask } from '../../utils/adaptField';
import { cpfMask, phoneMask, birthDateMask } from '../../utils/masks';
import {
  mainForm,
  formAlignment,
  fieldPadding,
  ButtonPadding,
  prompTextWrapper,
  promptText,
} from '../Form/Form.styles';
import Input from '../FormikInput';
import Checkbox from '../FormikCheckbox';
import PasswordHinter from '../PasswordHinter';
import { ReactComponent as InfoIcon } from '../../assets/info_outline.svg';
import Icon from '../Icon';

import requiredFormSchema from './requiredFormSchema';

const mapStateToProps = ({ tenantEyepass: { tenantId } }) => ({
  tenantId,
});

const getInitialValues = ({ initialValues }) => ({
  fullName: initialValues.fullName || '',
  email: initialValues.email || '',
  phone: initialValues.phone ? adaptPhone(initialValues.phone) : '',
  birthdate: initialValues.birthdate || '',
  document: initialValues.document ? adaptDocument(initialValues.document) : '',
  hasDocument: initialValues.isPreRegistered && !!initialValues.document,
  password: '',
  passwordConfirmation: '',
  acceptedMarketing: initialValues.acceptedMarketing || false,
  acceptedTOS: initialValues.acceptedTOS || false,
});

const RequiredDataForm = ({
  values,
  touched,
  errors,
  handleSubmit,
  setValues,
  setFieldValue,
  handleBlur,
  onIncompleteDataFound,
  onNoUserDataFound,
  isSubmitting,
  isValid,
  noAutoFetchCustomer,
  tenantId,
  onOpenTosModal,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isValidCustomer, setIsValidCustomer] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [lastValidation, setLastValidation] = useState({});

  const handleFetchIncompleteUserData = async () => {
    const { phone, document } = values;

    try {
      setIsLoading(true);
      setIsValidCustomer(false);
      setErrorMessage('');
      const payload = {
        phone: stripMask(phone),
        document: stripMask(document),
        tenantId,
      };

      const response = await http
        .post('/customers/find', payload)
        .then(({ data }) => data);

      if (response.data.customer) {
        const { customer } = response.data;

        onIncompleteDataFound(customer);

        setValues({
          ...values,
          fullName: customer.fullName,
          email: customer.email,
          birthdate: customer.birthdate,
        });
        setIsValidCustomer(true);
      }
    } catch (error) {
      const responseStatus = error?.response?.status;
      const responseMessage = error?.response?.data?.data?.message;

      if (responseStatus === 422 && responseMessage) {
        setIsValidCustomer(false);
        showToast(
          t('formValidation:requiredUserDataAlreadyRegistered'),
          'error',
        );
        setErrorMessage(t('formValidation:requiredUserDataAlreadyRegistered'));
      } else {
        setIsValidCustomer(true);
      }

      setLastValidation({ phone, document });

      onNoUserDataFound();

      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDocumentPhoneBlur = async (e) => {
    handleBlur(e);

    const { phone, document } = values;

    const hasValidInput =
      phone && !errors.phone && document && !errors.document;
    const lastValidationChanged =
      lastValidation.phone !== phone || lastValidation.document !== document;

    const shouldFetch = hasValidInput && lastValidationChanged;

    if (shouldFetch) {
      handleFetchIncompleteUserData();
    }
  };

  const handleEmailBlurTrim = async (e) => {
    const { email } = values;
    setFieldValue('email', email ? email.trim() : '');
    handleBlur(e);
  };

  const handleEmailChange = async (e) => {
    const { value } = e.target;
    setFieldValue('email', value ? value.trim() : '');
  };

  return (
    <form onSubmit={handleSubmit} css={mainForm}>
      <div css={formAlignment}>
        <div css={fieldPadding}>
          <Input
            name="document"
            placeholder={t('forms:document')}
            animateLabel
            mask={cpfMask}
            hideIcons={false}
            disabled={values.hasDocument || isLoading}
            onBlur={!noAutoFetchCustomer && handleDocumentPhoneBlur}
            inputMode="numeric"
            pattern="[0-9]*"
            variant="bordered"
            borderColor="primary"
            labelColor="primary"
            error={
              !isLoading && !isValidCustomer && touched.document && errorMessage
            }
          />
        </div>
        <div css={fieldPadding}>
          <Input
            name="phone"
            placeholder={t('forms:phone')}
            animateLabel
            mask={phoneMask}
            hideIcons={false}
            disabled={isLoading}
            onBlur={!noAutoFetchCustomer && handleDocumentPhoneBlur}
            inputMode="numeric"
            pattern="[0-9]*"
            variant="bordered"
            borderColor="primary"
            labelColor="primary"
            error={
              !isLoading && !isValidCustomer && touched.document && errorMessage
            }
          />
        </div>
        <div css={fieldPadding}>
          <Input
            name="fullName"
            placeholder={t('forms:fullname')}
            animateLabel
            hideIcons={false}
            disabled={values.hasFullName || isLoading}
            borderColor="primary"
            labelColor="primary"
            variant="bordered"
          />
        </div>
        <div css={fieldPadding}>
          <Input
            name="email"
            placeholder={t('forms:email')}
            animateLabel
            hideIcons={false}
            disabled={isLoading}
            borderColor="primary"
            labelColor="primary"
            variant="bordered"
            onChange={handleEmailChange}
            onBlur={handleEmailBlurTrim}
          />
        </div>
        <div css={fieldPadding}>
          <div css={prompTextWrapper}>
            <Icon
              icon={<InfoIcon />}
              size="30px"
              wrapperStyle={{ marginRight: '5px' }}
              color="text"
            />
            <span css={promptText}>{t('forms:birthdayPrompt')}</span>
          </div>
          <Input
            name="birthdate"
            placeholder={t('forms:birthday')}
            animateLabel
            mask={birthDateMask}
            hideIcons={false}
            disabled={isLoading}
            inputMode="numeric"
            pattern="[0-9]*"
            borderColor="primary"
            labelColor="primary"
            variant="bordered"
          />
        </div>
        <div css={fieldPadding}>
          <Input
            name="password"
            placeholder={t('forms:password')}
            animateLabel
            type="password"
            hideIcons={false}
            inputStyle={{
              letterSpacing: 4,
            }}
            borderColor="primary"
            labelColor="primary"
            variant="bordered"
          />
        </div>
        <div css={fieldPadding}>
          <Input
            name="passwordConfirmation"
            placeholder={t('forms:passwordConfirmation')}
            animateLabel
            type="password"
            hideIcons={false}
            inputStyle={{
              letterSpacing: 4,
            }}
            borderColor="primary"
            labelColor="primary"
            variant="bordered"
          />
        </div>
        <div css={fieldPadding}>
          <PasswordHinter />
        </div>
        <div css={fieldPadding}>
          <Checkbox
            name="acceptedMarketing"
            label={t('forms:marketingAcceptance')}
          />
        </div>
        <div css={fieldPadding}>
          <Checkbox
            name="acceptedTOS"
            label={
              <>
                {t('forms:TOSAcceptance')}{' '}
                <span
                  style={{ fontWeight: 'bold', textDecoration: 'underline' }}
                  onClick={onOpenTosModal}
                  onKeyDown={onOpenTosModal}
                  role="presentation"
                >
                  {t('forms:TOS')}
                </span>
              </>
            }
          />
        </div>
        <ButtonPadding>
          <Button
            isLoading={isSubmitting || isLoading}
            disabled={!isValid || isLoading || !isValidCustomer}
            onClick={handleSubmit}
            color="primary"
          >
            {t('navigation:next')}
          </Button>
        </ButtonPadding>
      </div>
    </form>
  );
};

RequiredDataForm.defaultProps = {
  noAutoFetchCustomer: false,
};

RequiredDataForm.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  onIncompleteDataFound: PropTypes.func.isRequired,
  onNoUserDataFound: PropTypes.func.isRequired,
  noAutoFetchCustomer: PropTypes.bool,
  tenantId: PropTypes.number.isRequired,
  onOpenTosModal: PropTypes.func.isRequired,
};

const formikEnhance = withFormik({
  mapPropsToValues: getInitialValues,
  validationSchema: requiredFormSchema,
  handleSubmit: (values, { props, dirty, ...formikBag }) => {
    const { onFormSubmit } = props;
    onFormSubmit(values, formikBag);
  },
  enableReinitialize: true,
});

const enhancedForm = formikEnhance(RequiredDataForm);

enhancedForm.propTypes = {
  initialValues: PropTypes.shape({
    fullName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    birthdate: PropTypes.string,
    document: PropTypes.string,
  }).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  isPreRegistered: PropTypes.bool,
  onIncompleteDataFound: PropTypes.func.isRequired,
  noAutoFetchCustomer: PropTypes.bool,
};

export default connect(mapStateToProps)(enhancedForm);

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from '../../components/Modal';
import CircleButton from '../../components/CircleButton';

import { ReactComponent as KeyboardArrowDown } from '../../assets/keyboard_arrow_down.svg';
import UserAddressForm from '../../components/UserAddressForm';
import withModal from '../../hocs/withModal';
import {
  productContent,
  productInnerWrapper,
  productOverlay,
  container,
  menuHeader,
  menuTitle,
  contentScroll,
} from '../../components/ProductAdjustModal/ProductAdjustModal.styles';

const AddressEditModal = ({ visible, onClose, onFinish, addressItem }) => {
  const { t } = useTranslation();

  const initialValues = {
    addressLabel: addressItem.label,
    addressReceiverName: addressItem.receiver_name,
    addressZipcode: addressItem.zipcode,
    addressStreet: addressItem.address,
    addressNumber: addressItem.number,
    addressComplement: addressItem.address2,
    addressDistrict: addressItem.district,
    addressCity: addressItem.city,
    addressState: addressItem.state,
    main: addressItem.main,
  };

  const handleFinish = (addressData, formikBag) => {
    onFinish(addressData, formikBag, addressItem.id);
  };

  return (
    <Modal
      visible={visible}
      styles={{
        innerWrapper: productInnerWrapper,
        content: productContent,
        overlay: productOverlay,
      }}
      keepMounted
    >
      <div css={container}>
        <div css={menuHeader}>
          <CircleButton
            icon={<KeyboardArrowDown />}
            size="30px"
            onClick={onClose}
            backgroundColor="primary"
            color="background"
            padding="3px"
          />
          <span css={menuTitle}>{t('addressesListPage:editAddress')}</span>
          <div style={{ width: '30px' }} />
        </div>
        <div css={contentScroll}>
          <UserAddressForm
            onFormSubmit={handleFinish}
            initialValues={initialValues}
          />
        </div>
      </div>
    </Modal>
  );
};

AddressEditModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  addressItem: PropTypes.object.isRequired,
};

export default withModal((props) => ({
  visible: props.visible,
}))(AddressEditModal);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CircleButton from '../CircleButton';

import addIcon from '../../assets/add_icon.svg';
import removeIcon from '../../assets/remove_icon.svg';
import localizePrice from '../../utils/localizePrice';

import {
  itemContainer,
  radioButtonItemContainer,
  itemDescription,
  DescriptionText,
  valueText,
  buttonRow,
  quantityWrapper,
  RadioButtonInput,
  radioButtonCheck,
} from './ComplementItem.styles';

const mapStateToProps = ({
  productOrder: {
    productOrder: { store },
  },
}) => ({
  menuOnly: Boolean(store) && store.isMenuOnly,
});

const ComplementItem = ({
  name,
  description,
  quantity,
  groupMax,
  disabled,
  price,
  onIncrement,
  onDecrement,
  menuOnly,
}) => {
  const renderComplementItemDescription = () => (
    <div css={itemDescription}>
      <DescriptionText style={{ marginBottom: 8 }}>{name}</DescriptionText>
      <span css={valueText} style={{ marginBottom: 8 }}>
        {price > 0 && ` (+ R$ ${localizePrice(price)})`}
      </span>
      {description && (
        <DescriptionText textColor="placeholder">{description}</DescriptionText>
      )}
    </div>
  );

  if (menuOnly) {
    return <div css={itemContainer}>{renderComplementItemDescription()}</div>;
  }

  if (groupMax === 1) {
    return (
      <div
        css={radioButtonItemContainer}
        onClick={quantity > 0 ? onDecrement : onIncrement}
        onKeyDown={quantity > 0 ? onDecrement : onIncrement}
        role="presentation"
        aria-label={name}
      >
        {renderComplementItemDescription()}
        <RadioButtonInput>
          {quantity > 0 && <div css={radioButtonCheck} />}
        </RadioButtonInput>
      </div>
    );
  }

  const isVisible = quantity > 0;

  return (
    <div css={itemContainer}>
      {renderComplementItemDescription()}
      <div css={buttonRow}>
        {isVisible && (
          <>
            <CircleButton
              src={removeIcon}
              size="30px"
              onClick={onDecrement}
              backgroundColor="error"
              padding="5px"
            />
            <div css={quantityWrapper}>
              <DescriptionText>{quantity}</DescriptionText>
            </div>
          </>
        )}
        <CircleButton
          src={addIcon}
          size="30px"
          onClick={onIncrement}
          backgroundColor="success"
          padding="5px"
          disabled={disabled}
        />
      </div>
    </div>
  );
};

ComplementItem.defaultProps = {
  disabled: false,
};

ComplementItem.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  groupMax: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  price: PropTypes.number.isRequired,
  onIncrement: PropTypes.func.isRequired,
  onDecrement: PropTypes.func.isRequired,
  menuOnly: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(ComplementItem);

import React, { useEffect, useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';
import {
  Overlay,
  innerCss,
  wrapperCss,
  contentCss,
  sizes,
  innerWrapperCss,
} from './Modal.styles';

const Modal = forwardRef(
  (
    {
      size,
      padding,
      visible,
      children,
      topOffset,
      keepMounted,
      styles,
      overlay,
    },
    ref,
  ) => {
    useEffect(() => {
      const element = document.getElementById('root');
      if (visible) {
        element.setAttribute('style', 'overflow: hidden;');
      }

      return () => {
        element.removeAttribute('style');
      };
    }, [visible]);

    const wrapperStyle = useMemo(() => {
      if (keepMounted) {
        return { display: visible ? 'block' : 'none' };
      }

      return {};
    }, [keepMounted, visible]);

    const innerWrapperStyle = useMemo(
      () => ({
        top: topOffset || '50%',
        maxWidth: sizes[size],
        transform: `translate(-50%, ${topOffset ? '0' : '-50%'})`,
        marginBottom: topOffset,
      }),
      [topOffset, size],
    );

    const contentStyle = useMemo(
      () => ({
        padding: padding || '25px',
      }),
      [padding],
    );

    const computeStyles = useMemo(() => {
      const resultStyles = {
        wrapper: {
          ...wrapperStyle,
          ...(styles.wrapper || {}),
        },
        innerWrapper: {
          ...innerWrapperStyle,
          ...(styles.innerWrapper || {}),
        },
        content: {
          ...contentStyle,
          ...(styles.content || {}),
        },
        inner: {
          ...(styles.inner || {}),
        },
        overlay: styles.overlay,
      };

      return resultStyles;
    }, [styles, overlay, keepMounted, visible]);

    if (!keepMounted && !visible) return null;

    return (
      <Portal>
        <div css={wrapperCss} style={computeStyles.wrapper}>
          <Overlay background={overlay} style={computeStyles.overlay}>
            <div css={innerCss} style={computeStyles.inner}>
              <div css={innerWrapperCss} style={computeStyles.innerWrapper}>
                <div ref={ref} css={contentCss} style={computeStyles.content}>
                  {children}
                </div>
              </div>
            </div>
          </Overlay>
        </div>
      </Portal>
    );
  },
);

Modal.defaultProps = {
  visible: false,
  keepMounted: false,
  size: 'small',
  topOffset: undefined,
  padding: undefined,
  styles: {},
  overlay: true,
};

Modal.propTypes = {
  children: PropTypes.element.isRequired,
  keepMounted: PropTypes.bool,
  padding: PropTypes.string,
  /** Sets modal size, available sizes:
   * small: 375px,
   * medium: 535px,
   * mediumPlus: 620px,
   * large: 700px
   */
  size: PropTypes.string,
  topOffset: PropTypes.string,
  visible: PropTypes.bool,
  styles: PropTypes.shape({
    overlay: PropTypes.object,
    content: PropTypes.object,
    wrapper: PropTypes.object,
    inner: PropTypes.object,
    innerWrapper: PropTypes.object,
  }),
  overlay: PropTypes.bool,
};

export default Modal;

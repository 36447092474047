import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Input from '../EyeInput';
import Button from '../ButtonBit';

import { mainForm, fieldPadding, ButtonPadding } from '../Form/Form.styles';

const tagFormSchema = Yup.object().shape({
  tag: Yup.string().required('formValidation:fieldRequired'),
});

const TagForm = ({
  values,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  errors,
  isValid,
  onCancel,
}) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleSubmit} css={mainForm}>
      <div css={fieldPadding}>
        <Input
          value={values.tag}
          touched={touched.tag}
          isValid={!errors.tag}
          error={t(errors.tag)}
          placeholder={t('forms:tableAlter')}
          animateLabel
          name="tag"
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </div>
      <ButtonPadding hasMultiButtons={!!onCancel}>
        {onCancel && (
          <Button onClick={onCancel} color="error">
            {t('formValidation:cancel')}
          </Button>
        )}
        <Button onClick={handleSubmit} color="primary" disabled={!isValid}>
          {t('forms:confirm')}
        </Button>
      </ButtonPadding>
    </form>
  );
};

TagForm.defaultProps = {
  onCancel: null,
};

TagForm.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  isValid: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
};

const formikEnhance = withFormik({
  mapPropsToValues: ({ initialValues }) => ({
    tag: initialValues.tag || '',
  }),
  validationSchema: tagFormSchema,
  handleSubmit: (values, { props, dirty, ...formikBag }) => {
    const { onFormSubmit } = props;
    onFormSubmit(values, formikBag);
  },
  enableReinitialize: true,
});

const enhancedForm = formikEnhance(TagForm);

enhancedForm.defaultProps = {
  onCancel: null,
};

enhancedForm.propTypes = {
  initialValues: PropTypes.shape({
    tag: PropTypes.string,
  }).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

export default enhancedForm;

import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { useTheme } from 'emotion-theming';
import { has } from 'object-path';
import colors from '../constants';

import {
  getButtonText,
  loadingClassName,
  StyledButton,
  startIconWrapper,
  endIconWrapper,
} from './Button.styles';

const Button = ({
  style,
  children,
  disabled,
  isLoading,
  onClick,
  className,
  block,
  color,
  styles,
  startIcon,
  endIcon,
  variant,
  textColor,
  onKeyPress,
  onKeyDown,
  onKeyUp,
  tabIndex,
  type,
  form,
}) => {
  const theme = useTheme();

  const getRootStyle = () => {
    if (style) return style;

    if (Object.prototype.hasOwnProperty.call(theme, 'getColor')) {
      if (has(styles, 'root')) return styles.root(theme);
    }

    return undefined;
  };

  const getLoadingStyle = () => {
    if (Object.prototype.hasOwnProperty.call(theme, 'getColor')) {
      if (has(styles, 'loading')) return styles.loading(theme);

      if (variant === 'outlined' || variant === 'reversed') {
        return getButtonText({ theme, color });
      }

      return getButtonText({ theme, color: textColor });
    }

    return variant === 'outlined' || variant === 'reversed'
      ? colors.default
      : 'white';
  };

  const getIconStyle = () => {
    if (Object.prototype.hasOwnProperty.call(theme, 'getColor')) {
      if (has(styles, 'icon')) return styles.icon(theme);
    }

    return undefined;
  };

  const renderStartIcon = () => {
    if (!startIcon) return null;

    const clonedStartIcon = React.cloneElement(startIcon, {
      style: getIconStyle(),
    });

    // eslint-disable-next-line react/no-unknown-property
    return <span css={startIconWrapper}>{clonedStartIcon}</span>;
  };

  const renderEndIcon = () => {
    if (!endIcon) return null;

    const clonedEndIcon = React.cloneElement(endIcon, {
      style: getIconStyle(),
    });

    // eslint-disable-next-line react/no-unknown-property
    return <span css={endIconWrapper}>{clonedEndIcon}</span>;
  };

  return (
    <StyledButton
      style={getRootStyle()}
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={className}
      color={color}
      textColor={textColor}
      block={block}
      variant={variant}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onKeyPress={onKeyPress}
      tabIndex={tabIndex}
      form={form}
    >
      {renderStartIcon()}
      {children}
      {renderEndIcon()}
      {isLoading && (
        <div css={loadingClassName}>
          <ReactLoading
            type="spin"
            height={20}
            width={20}
            color={getLoadingStyle()}
          />
        </div>
      )}
    </StyledButton>
  );
};

Button.defaultProps = {
  block: false,
  disabled: false,
  isLoading: false,
  className: undefined,
  color: 'button',
  textColor: 'buttonText',
  style: undefined,
  styles: undefined,
  startIcon: undefined,
  endIcon: undefined,
  variant: 'default',
  onClick: undefined,
  onKeyPress: undefined,
  onKeyUp: undefined,
  onKeyDown: undefined,
  tabIndex: undefined,
  type: 'button',
  form: undefined,
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.func,
    PropTypes.string,
  ]).isRequired,
  block: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  textColor: PropTypes.string,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  style: PropTypes.object,
  styles: PropTypes.shape({
    root: PropTypes.func,
    loading: PropTypes.func,
    icon: PropTypes.func,
  }),
  startIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  endIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  variant: PropTypes.string,
  tabIndex: PropTypes.number,
  type: PropTypes.string,
  form: PropTypes.string,
};

export default Button;

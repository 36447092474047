import { css } from '@emotion/core';
import styled from '@emotion/styled';
import facepaint from 'facepaint';
import colors from '../constants';

const mq = facepaint(['@media screen and (min-width: 375px)']);

export const loadingClassName = (theme) => css`
  width: 18px;
  height: 18px;
  margin-left: ${(theme && theme.getSpacing && theme.getSpacing(1, 'px')) ||
  '8px'};
`;

export const getButtonBackground = ({ theme, color }) => {
  if (!Object.prototype.hasOwnProperty.call(theme, 'getColor')) {
    return colors.default;
  }

  return theme.getColor(color);
};

export const getButtonText = ({ theme, color }) => {
  if (!Object.prototype.hasOwnProperty.call(theme, 'getColor')) {
    return 'white';
  }

  return theme.getColor(color);
};

const getPadding = ({ theme }) => {
  if (Object.prototype.hasOwnProperty.call(theme, 'getSpacing')) {
    return mq({
      paddingTop: [theme.getSpacing(1, 'px')],
      paddingBottom: [theme.getSpacing(1, 'px')],
      paddingLeft: [theme.getSpacing(2, 'px'), theme.getSpacing(3, 'px')],
      paddingRight: [theme.getSpacing(2, 'px'), theme.getSpacing(3, 'px')],
    });
  }

  return mq({
    paddingTop: [8],
    paddingBottom: [8],
    paddingLeft: [16, 24],
    paddingRight: [16, 24],
  });
};

const getFontSize = ({ theme }) => {
  if (Object.prototype.hasOwnProperty.call(theme, 'getSpacing')) {
    return mq({
      fontSize: [theme.getFontSize(0.8, 'px'), theme.getFontSize(1, 'px')],
    });
  }

  return mq({
    fontSize: [8, 12],
  });
};

const getFontFamily = ({ theme }) => {
  if (Object.prototype.hasOwnProperty.call(theme, 'getFont')) {
    return {
      fontFamily: theme.getFont('futura'),
    };
  }

  return {
    fontFamily: 'Futura Medium, Arial, sans-sarif',
  };
};

const getVariantStyles = ({ theme, color, textColor, variant }) => {
  const buttonBackground = getButtonBackground({ theme, color });
  const buttonText = getButtonText({ theme, color: textColor });

  const variants = {
    default: {
      backgroundColor: buttonBackground,
      border: 'none',
      color: buttonText,
    },
    outlined: {
      backgroundColor: 'transparent',
      border: `1px solid ${buttonBackground}`,
      color: buttonBackground,
    },
    reversed: {
      backgroundColor: buttonText,
      border: 'none',
      color: buttonBackground,
    },
  };

  return variants[variant] || variants.default;
};

export const StyledButton = styled.button`
  min-width: auto;
  width: ${({ block }) => (block ? '100%' : 'auto')};
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  opacity: 1;
  border-radius: 20px;
  cursor: pointer;
  ${getFontSize}
  ${getPadding};
  ${getFontFamily}
  text-transform: uppercase;
  white-space: nowrap;
  line-height: 1;
  transition: color 0.15s ease-in-out, opacity 0.15s ease-in-out,
    background-color 0.15s ease-in-out;
  ${(props) => getVariantStyles(props)}

  &.selected {
    opacity: 1;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }
`;

export const startIconWrapper = (theme) => css`
  margin-right: ${theme && theme.getSpacing
    ? theme.getSpacing(1, 'px')
    : '8px'};
  line-height: 0;
  font-size: 0;
`;

export const endIconWrapper = (theme) => css`
  margin-left: ${theme && theme.getSpacing ? theme.getSpacing(1, 'px') : '8px'};
  line-height: 0;
  font-size: 0;
`;

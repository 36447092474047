import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Loading from '../LoadingBit';
import CircleButton from '../CircleButton';
import Button from '../ButtonBit';

import { ReactComponent as KeyboardArrowDown } from '../../assets/keyboard_arrow_down.svg';
import dayjs from '../../utils/dayjs';
import DropdownMenu from '../DropdownMenu';
import RowRadioButton from '../RowRadioButton';

import {
  menuWrapper,
  bottomButtonsRow,
  slotCheckboxWrapper,
  radioLabelCss,
  menuTitleWrapper,
  scheduleMenuLabel,
  menuTitle,
  dropdownWrapper,
  contentScroll,
} from './DeliveryScheduleMenu.styles';

const DeliveryScheduleMenu = ({
  availableSlots,
  scheduleSlotMaxOrders,
  availableSearchDays,
  scheduleSearchDay,
  onSearchDayChange,
  selectedSchedule,
  onNewSelectedSchedule,
  onCofirm,
  onCancel,
  isLoading,
}) => {
  const { t } = useTranslation();
  const selectedScheduleId = selectedSchedule
    ? `${selectedSchedule.start}${selectedSchedule.end}`
    : null;

  const handleCheckboxClick = (slot) => () => onNewSelectedSchedule(slot);

  const renderSlot = (slot) => {
    const { start, end, orders: ordersAmount } = slot;
    const slotDisabled = ordersAmount >= scheduleSlotMaxOrders;
    const slotId = `${start}${end}`;
    const adaptedStart = dayjs(start).format('HH:mm');
    const adaptedEnd = dayjs(end).format('HH:mm');

    const label = `${adaptedStart} - ${adaptedEnd}`;

    return (
      <div css={slotCheckboxWrapper} key={slotId}>
        <RowRadioButton
          name={`slot-${slotId}`}
          checked={selectedScheduleId === slotId}
          onChange={handleCheckboxClick(slot)}
          label={label}
          disabled={slotDisabled}
          labelCss={radioLabelCss}
        />
      </div>
    );
  };

  const options = useMemo(
    () =>
      availableSearchDays.map((day) => ({
        id: day,
        label: dayjs(day).format('DD/MM/YYYY, dddd'),
      })),
    [availableSearchDays],
  );

  const label = useMemo(
    () => dayjs(scheduleSearchDay).format('DD/MM/YYYY, dddd'),
    [scheduleSearchDay],
  );

  const renderMenuContent = useMemo(() => {
    if (isLoading) {
      return (
        <Loading
          show
          center
          style={{ height: 'auto', flexGrow: 0, marginTop: 24 }}
        />
      );
    }

    if (!availableSlots || availableSlots.length === 0) {
      return (
        <div css={menuTitleWrapper}>
          <span
            css={scheduleMenuLabel}
            style={{ fontWeight: 'bold', textAlign: 'center' }}
          >
            {t('orderReview:noScheduleAvailable')}
          </span>
        </div>
      );
    }

    return availableSlots && availableSlots.map(renderSlot);
  }, [availableSlots, isLoading, selectedScheduleId]);

  return (
    <div css={menuWrapper}>
      <div css={menuTitleWrapper}>
        <CircleButton
          icon={<KeyboardArrowDown />}
          size="30px"
          onClick={onCancel}
          backgroundColor="primary"
          color="background"
          padding="3px"
        />
        <span css={menuTitle}>{t('orderReview:scheduleDelivery')}</span>
        <div style={{ width: '30px' }} />
      </div>
      <div css={dropdownWrapper}>
        <DropdownMenu
          id="scheduleSearchDay"
          label={label}
          options={options}
          onOptionSelect={onSearchDayChange}
          disabled={isLoading}
        />
      </div>
      <div css={menuTitleWrapper}>
        <span css={scheduleMenuLabel}>
          {t('orderReview:chooseDeliverySchedule')}
        </span>
      </div>
      <div css={contentScroll}>{renderMenuContent}</div>
      <div css={bottomButtonsRow}>
        <Button
          color="primary"
          onClick={onCofirm}
          disabled={isLoading || !selectedScheduleId}
        >
          {t('orderReview:schedule')}
        </Button>
      </div>
    </div>
  );
};

DeliveryScheduleMenu.defaultProps = {
  availableSlots: [],
  availableSearchDays: [],
  scheduleSlotMaxOrders: 1,
  isLoading: false,
};

DeliveryScheduleMenu.propTypes = {
  availableSlots: PropTypes.array,
  availableSearchDays: PropTypes.array,
  scheduleSlotMaxOrders: PropTypes.number,
  scheduleSearchDay: PropTypes.object.isRequired,
  onSearchDayChange: PropTypes.func.isRequired,
  selectedSchedule: PropTypes.object.isRequired,
  onNewSelectedSchedule: PropTypes.func.isRequired,
  onCofirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default DeliveryScheduleMenu;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from '../../components/Modal';

import gateway from '../../utils/gateway';
import showToast from '../../utils/showToast';
import VouchersService from '../../services/vouchers';
import OrderReviewGiftCardForm from './OrderReviewGiftCardForm';

const OrderReviewGiftCardModal = ({
  visible,
  onClose,
  onConfirm,
  subtotal,
  deliveryPrice,
}) => {
  const { t } = useTranslation();

  const validateCardCode = async (values, formikBag) => {
    try {
      formikBag.setSubmitting(true);
      const response = await gateway
        .get(VouchersService.validateCard, {
          params: { code: values.voucherCode, value: subtotal + deliveryPrice },
        })
        .then(({ data }) => data);

      const { id, balance, one_time_usage: oneTimeUsage } = response.gift_card;

      showToast(t('orderReview:giftCardValid'), 'success');
      onConfirm({
        id,
        total: balance,
        code: values.voucherCode,
        oneTimeUsage,
      });
      formikBag.setSubmitting(false);
      onClose();
    } catch (e) {
      console.error(e);
      if (e?.response?.status === 400) {
        showToast(t('orderReview:giftCardInvalid'), 'error');
      } else {
        showToast(t('orderReview:giftCardRequestError'), 'error');
      }
      formikBag.setSubmitting(false);
    }
  };

  return (
    <Modal visible={visible}>
      <OrderReviewGiftCardForm onConfirm={validateCardCode} onClose={onClose} />
    </Modal>
  );
};

OrderReviewGiftCardModal.defaultProps = {
  visible: false,
};

OrderReviewGiftCardModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  subtotal: PropTypes.number.isRequired,
  deliveryPrice: PropTypes.number.isRequired,
};

export default OrderReviewGiftCardModal;

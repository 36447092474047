import styled from '@emotion/styled';

const LoadingContainer = styled.div`
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 18px;
  flex-direction: column;
  text-transform: none !important;
  ${(props) => props.center && 'flex-grow: 1; margin-top: 0;'}
`;

export default LoadingContainer;

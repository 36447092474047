import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CircleButton from '../CircleButton';
import Button from '../ButtonBit';

import { ReactComponent as KeyboardArrowDown } from '../../assets/keyboard_arrow_down.svg';
import {
  filterList,
  filtersHeader,
  filtersTitle,
  contentScroll,
  FilterItem,
  FilterItemText,
  footer,
} from './OrderFilter.styles';

const OrderFilters = ({
  filters,
  selectedFilters,
  onSelect,
  onRemove,
  onClose,
  onClearFilters,
  onFinish,
}) => {
  const { t } = useTranslation();

  const renderFilter = (filter) => {
    const isSelected = selectedFilters.some(
      ({ menuProductGroupId }) =>
        filter.menuProductGroupId === menuProductGroupId,
    );
    return (
      <FilterItem
        key={filter.menuProductGroupId}
        active={isSelected}
        role="presentation"
        onClick={isSelected ? () => onRemove(filter) : () => onSelect(filter)}
        onKeyDown={isSelected ? () => onRemove(filter) : () => onSelect(filter)}
      >
        <FilterItemText active={isSelected}>{filter.name}</FilterItemText>
      </FilterItem>
    );
  };

  return (
    <div css={filterList}>
      <div css={filtersHeader}>
        {selectedFilters.length > 0 ? (
          <Button
            onClick={onClearFilters}
            color="primary"
            variant="outlined"
            disabled={selectedFilters.length === 0}
          >
            {t('productSearch:clear')}
          </Button>
        ) : (
          <div style={{ width: '30px' }} />
        )}
        <CircleButton
          icon={<KeyboardArrowDown />}
          size="30px"
          onClick={onClose}
          backgroundColor="primary"
          color="background"
          padding="3px"
        />
      </div>
      <span css={filtersTitle}>{t('productOrder:selectFilters')}</span>
      <div css={contentScroll}>{filters.map(renderFilter)}</div>
      <div css={footer}>
        <Button onClick={onFinish} color="primary" block>
          {t('forms:confirm')}
        </Button>
      </div>
    </div>
  );
};

OrderFilters.propTypes = {
  filters: PropTypes.array.isRequired,
  selectedFilters: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default OrderFilters;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Modal from '../../components/Modal';

import EcommerceService from '../../services/ecommerce';
import clean from '../../utils/clean';
import fetchGeolocation from '../../utils/fetchGeolocation';
import showToast from '../../utils/showToast';
import gateway from '../../utils/gateway';
import mapStore from '../../utils/mapStore';
import withModal from '../../hocs/withModal';
import FirstStoreSelect from '../../components/FirstStoreSelect';

const mapStateToProps = ({ tenantEyepass: { tenantId } }) => ({
  tenantId,
});

const ContextStoreSelectModal = ({
  visible,
  isLogged,
  onStoreSelect,
  onDeny,
  tenantId,
}) => {
  const [isLoading, setisLoading] = useState(false);
  const [store, setStore] = useState(null);

  const { t } = useTranslation();

  const fetchStores = async () => {
    setisLoading(true);

    let position = null;

    try {
      position = await fetchGeolocation();
    } catch (e) {
      showToast(t('warnings:geoLocationDisabled'), 'warning');
    }

    try {
      let pointParams = {};
      if (position && position.coords) {
        pointParams = {
          ...pointParams,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
      }
      if (!isLogged) {
        pointParams = {
          ...pointParams,
          tenant_id: tenantId,
        };
      }

      const response = await gateway
        .get(EcommerceService.getEventPoints(), { params: clean(pointParams) })
        .then(({ data }) => data);

      if (response.event_points) {
        const storeList = response.event_points
          .filter(({ open }) => !!open)
          .map(mapStore);
        if (storeList.length > 0) {
          setStore(storeList[0]);
        }
        setisLoading(false);
      } else {
        setisLoading(false);
      }
    } catch (e) {
      console.error(e);
      showToast(t('errors:fetchPointsFailure'), 'error');
      setisLoading(false);
    }
  };

  useEffect(() => {
    fetchStores();
  }, []);

  const handleStoreSelect = () => {
    onStoreSelect(store);
  };

  return (
    <Modal visible={visible}>
      <FirstStoreSelect
        storeData={store}
        onAccept={handleStoreSelect}
        onDeny={onDeny}
        isLoading={isLoading}
      />
    </Modal>
  );
};

ContextStoreSelectModal.defaultProps = {
  visible: false,
};

ContextStoreSelectModal.propTypes = {
  visible: PropTypes.bool,
  isLogged: PropTypes.bool.isRequired,
  onStoreSelect: PropTypes.func.isRequired,
  onDeny: PropTypes.func.isRequired,
  tenantId: PropTypes.number.isRequired,
};

const enhance = compose(
  withModal((props) => ({
    visible: props.visible,
  })),
  connect(mapStateToProps),
);

export default enhance(ContextStoreSelectModal);

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../ButtonBit';

import {
  headerText,
  confirmationWrapper,
  ModalText,
  spacedRow,
  buttonRow,
  buttonWrapper,
} from './CancelOrderConfirmation.styles';

const CancelOrderConfirmation = ({
  onConfirm,
  onCancel,
  orderId,
  orderStoreName,
  orderPrice,
}) => {
  const { t } = useTranslation();

  const promptText = t('orderHistory:cancelOrderWarning', {
    orderId,
  });

  return (
    <div css={confirmationWrapper}>
      <h2 css={headerText}>{t('orderHistory:cancelOrder')}</h2>
      <ModalText>{promptText}</ModalText>
      <div css={spacedRow}>
        <ModalText>{t('orderHistory:store')}</ModalText>
        <ModalText>{orderStoreName}</ModalText>
      </div>
      <div css={spacedRow}>
        <ModalText>{t('orderHistory:total')}</ModalText>
        <ModalText>{orderPrice}</ModalText>
      </div>
      <ModalText bold>{t('warnings:operationCantBeUndone')}</ModalText>
      <div css={buttonRow}>
        <div css={buttonWrapper}>
          <Button variant="outlined" color="primary" onClick={onCancel} block>
            {t('context:no')}
          </Button>
        </div>
        <div css={buttonWrapper}>
          <Button color="primary" onClick={onConfirm} block>
            {t('formValidation:yesconfirm')}
          </Button>
        </div>
      </div>
    </div>
  );
};

CancelOrderConfirmation.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  orderStoreName: PropTypes.string.isRequired,
  orderPrice: PropTypes.string.isRequired,
};

export default CancelOrderConfirmation;

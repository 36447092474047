import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../components/ButtonBit';

import SuccessIcon from '../../assets/checked.svg';
import ScrollableView from '../../components/ScrollableView';
import OrderPaymentStatusRefresher from './OrderPaymentStatusRefresher';
import Error from '../../components/Error';
import { shippingTypes } from '../../utils/constants';
import {
  backgroundCss,
  wrapperCss,
  messageCss,
  iconCss,
  buttonWrapperCss,
} from './OrderPaymentStatusPage.styles';

const OrderPaymentStatusScreen = ({
  errorMessage,
  onRefresh,
  onNewOrder,
  onFollowOrder,
  onReviewOrder,
  deliveryType,
  transactionStatus,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    let timeout;
    if (transactionStatus === 'completed') {
      timeout = setTimeout(onFollowOrder, 5000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [transactionStatus]);

  if (errorMessage) {
    return (
      <Error
        errorMessage={errorMessage}
        onReturn={onReviewOrder}
        buttonText={t('orderPayment:reviewOrder')}
      />
    );
  }

  if (transactionStatus === 'cancelled') {
    return (
      <Error
        errorMessage={t('orderPayment:rejectedTransaction')}
        onReturn={onReviewOrder}
        buttonText={t('orderPayment:reviewOrder')}
      />
    );
  }

  if (transactionStatus === 'rejected') {
    return (
      <Error
        errorMessage={t('orderPayment:rejectedTransaction')}
        onReturn={onReviewOrder}
        buttonText={t('orderPayment:reviewOrder')}
      />
    );
  }

  if (transactionStatus === 'completed') {
    const transactionMessage =
      deliveryType === shippingTypes.TAKEOUT
        ? t('orderPayment:completedTakeoutTransaction')
        : t('orderPayment:transactionMessage');

    return (
      <div css={backgroundCss}>
        <ScrollableView>
          <div css={wrapperCss}>
            <img src={SuccessIcon} alt="checked" css={iconCss} />
            <div css={messageCss}>{t('orderPayment:completedTransaction')}</div>
            <div css={messageCss}>{transactionMessage}</div>
            <div css={buttonWrapperCss}>
              <Button variant="outlined" color="uiText" onClick={onNewOrder}>
                {t('orderPayment:makeNewOrder')}
              </Button>
            </div>
            <div css={buttonWrapperCss}>
              <Button variant="outlined" color="uiText" onClick={onFollowOrder}>
                {t('orderPayment:followOrder')}
              </Button>
            </div>
          </div>
        </ScrollableView>
      </div>
    );
  }

  return <OrderPaymentStatusRefresher onRefresh={onRefresh} />;
};

OrderPaymentStatusScreen.defaultProps = {
  deliveryType: null,
  errorMessage: undefined,
};

OrderPaymentStatusScreen.propTypes = {
  errorMessage: PropTypes.string,
  onRefresh: PropTypes.func.isRequired,
  onNewOrder: PropTypes.func.isRequired,
  onFollowOrder: PropTypes.func.isRequired,
  onReviewOrder: PropTypes.func.isRequired,
  deliveryType: PropTypes.string,
  transactionStatus: PropTypes.string.isRequired,
};

export default OrderPaymentStatusScreen;

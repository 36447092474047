import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useScrollToBottom } from 'use-scroll-to-bottom';
import { useTranslation } from 'react-i18next';
import Loading from '../LoadingBit';

import ScrollableView from '../ScrollableView';

import {
  scrollMessagesWrapper,
  loaderWrapper,
  endListText,
} from './InfiniteScroll.styles';

const InfiniteScroll = ({
  items,
  renderItem,
  onFetchMore,
  hasMore,
  isLoading,
  hasHeader,
  hasFooter,
  hideScroll,
  id,
  flex,
  endListMessage,
  emptyListMessage,
  divCss,
}) => {
  const [setBottomRef, isBottom] = useScrollToBottom();

  const { t } = useTranslation();

  useEffect(() => {
    if (isBottom && hasMore && !isLoading) {
      onFetchMore();
    }
  }, [isBottom]);

  const endMessage = () => (
    <div style={{ textAlign: 'center', paddingTop: 25, paddingBottom: 25 }}>
      <span css={endListText}>{endListMessage || t('info:endList')}</span>
    </div>
  );

  const loader = () => (
    <div css={loaderWrapper}>
      <Loading show isSmall />
    </div>
  );

  const emptyMessage = () => (
    <div style={{ textAlign: 'center', paddingTop: 25, paddingBottom: 25 }}>
      <span css={endListText}>{emptyListMessage || t('info:emptyList')}</span>
    </div>
  );

  return (
    <ScrollableView
      id={id}
      hideScroll={hideScroll}
      hasHeader={hasHeader}
      hasFooter={hasFooter}
      flex={flex}
      divCss={divCss}
    >
      {items.map(renderItem)}
      {!isLoading && (
        <div css={scrollMessagesWrapper} ref={setBottomRef}>
          {isLoading && loader()}
          {!isLoading && items.length === 0 && emptyMessage()}
          {!hasMore && items.length > 0 && endMessage()}
        </div>
      )}
    </ScrollableView>
  );
};

InfiniteScroll.defaultProps = {
  hasHeader: false,
  hasFooter: false,
  hideScroll: false,
  id: undefined,
  flex: true,
  endListMessage: null,
  emptyListMessage: null,
  divCss: undefined,
};

InfiniteScroll.propTypes = {
  hasHeader: PropTypes.bool,
  hasFooter: PropTypes.bool,
  hideScroll: PropTypes.bool,
  id: PropTypes.string,
  flex: PropTypes.bool,
  items: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  onFetchMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  endListMessage: PropTypes.string,
  emptyListMessage: PropTypes.string,
  divCss: PropTypes.string,
};

export default InfiniteScroll;

import merge from 'lodash.merge';

const defaultValues = {
  spacing: {
    base: 8,
  },
  fontSize: {
    base: 14,
  },
  fonts: {
    default: 'Open Sans, Arial, sans-serif',
    heading: 'Open Sans, Arial, sans-serif',
    text: 'Open Sans, Arial, sans-serif',
    futura: 'Futura Medium, Arial, sans-sarif',
  },
  colors: {
    default: '#727588',
    primary: '#7D1DD1',
    secondary: '#C50A27',
    text: '#FFFFFF',
    lightText: '#FFFFFF',
    uiText: '#FFFFFF',
    placeholder: '#8E8E8E',
    background: '#F1F3F7',
    foreground: '#7D1DD1',
    success: '#07DCAC',
    error: '#FF4F65',
    info: '#2F86EB',
    warn: '#FFC021',
    disabled: '#737689',
    loginInput: '#7D1DD1',
    signupButton: '#FFFFFF',
    sideMenuButton: '#FFFFFF',
    sideMenuText: '#000000',
    button: '#7D1DD1',
    buttonText: '#FFFFFF',
  },
};

class DesignSystem {
  constructor(values = {}) {
    const mergedValues = merge(defaultValues, values);

    Object.assign(this, mergedValues);
  }

  updateValues = (newValues = {}) => {
    const mergedValues = merge(defaultValues, newValues);

    Object.assign(this, mergedValues);
  };

  getSpacing = (times = 1, unit = '') => {
    const spacing = this.spacing.base * times;
    return unit ? `${spacing}${unit}` : spacing;
  };

  getFontSize = (times = 1, unit = '') => {
    const fontSize = this.fontSize.base * times;

    return unit ? `${fontSize}${unit}` : fontSize;
  };

  getColor = (key, opts = {}) => {
    const color = this.colors[key];

    if (color) {
      return color;
    }

    if (opts.fallback) {
      return this.colors[opts.fallback];
    }

    if (opts.useDefault) {
      return this.colors.default;
    }

    return undefined;
  };

  getFont = (key) => this.fonts[key] || this.fonts.default;
}

export default DesignSystem;

import React, { useMemo, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { MiniButton, MiniButtonImage } from './CircleButton.styles';

const CircleButton = ({
  padding,
  size,
  backgroundColor,
  borderColor,
  borderWidth,
  src,
  onClick,
  color,
  style,
  icon,
  type,
}) => {
  const clonedIcon = useMemo(() => {
    if (icon) {
      return cloneElement(icon, {
        style: { width: '100%', height: '100%' },
      });
    }
  }, [icon]);

  return (
    <MiniButton
      size={size}
      padding={padding}
      borderColor={borderColor}
      borderWidth={borderWidth}
      backgroundColor={backgroundColor}
      onClick={onClick}
      color={color}
      style={style}
      type={type}
    >
      {icon && clonedIcon}
      {!icon && src && <MiniButtonImage src={src} />}
    </MiniButton>
  );
};

CircleButton.defaultProps = {
  padding: '2px',
  size: '30px',
  backgroundColor: undefined,
  borderColor: undefined,
  borderWidth: '0px',
  color: undefined,
  style: undefined,
  src: undefined,
  icon: null,
  onClick: undefined,
  type: 'button',
};

CircleButton.propTypes = {
  padding: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  borderWidth: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  onClick: PropTypes.func,
  color: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string,
};

export default CircleButton;

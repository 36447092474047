import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, scrollSpy } from 'react-scroll';
import ScrollContainer from 'react-indiana-drag-scroll';

import { tabsHeaders, TabHeader, tabHeaderWrapper } from './Tabs.styles';

const TabsHeaderContent = ({
  headers,
  onHeaderClick,
  currentTab,
  handleCurrentTabChange,
  containerId,
  id,
}) => {
  useEffect(() => {
    scrollSpy.update();
    handleCurrentTabChange(0)();
  }, [headers]);

  return (
    <div css={tabHeaderWrapper} id={id}>
      <ScrollContainer css={tabsHeaders}>
        {headers.map((title, headerIndex) => (
          <Link
            key={title}
            activeClass="active"
            to={`tab-${headerIndex}`}
            smooth
            spy
            offset={-50}
            duration={500}
            isDynamic
            containerId={containerId}
            onSetActive={handleCurrentTabChange(headerIndex)}
          >
            <TabHeader
              onClick={onHeaderClick(headerIndex)}
              active={headerIndex === currentTab}
            >
              {title}
            </TabHeader>
          </Link>
        ))}
      </ScrollContainer>
    </div>
  );
};

TabsHeaderContent.defaultProps = {
  containerId: undefined,
  id: 'tabs__header',
};

TabsHeaderContent.propTypes = {
  headers: PropTypes.array.isRequired,
  onHeaderClick: PropTypes.func.isRequired,
  currentTab: PropTypes.number.isRequired,
  handleCurrentTabChange: PropTypes.func.isRequired,
  containerId: PropTypes.string,
  id: PropTypes.string,
};

export default TabsHeaderContent;

import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import Input from '../EyeInput';

const FormikInput = ({
  hideIcons,
  placeholder,
  isValid,
  name,
  animateLabel,
  inputStyle,
  labelStyle,
  wrapperStyle,
  type,
  onBlur,
  error,
  component: Component,
  onChange,
  validate,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Field name={name} validate={validate}>
      {({ field, form: { touched, errors } }) => (
        <Component
          value={field.value}
          touched={touched[name]}
          isValid={isValid}
          error={t(errors[name]) || error}
          placeholder={placeholder}
          animateLabel={animateLabel}
          hideIcons={hideIcons}
          name={name}
          onBlur={onBlur || field.onBlur}
          onChange={onChange || field.onChange}
          inputStyle={inputStyle}
          labelStyle={labelStyle}
          wrapperStyle={wrapperStyle}
          type={type}
          {...rest}
        />
      )}
    </Field>
  );
};

FormikInput.defaultProps = {
  hideIcons: false,
  isValid: undefined,
  placeholder: undefined,
  animateLabel: true,
  inputStyle: undefined,
  labelStyle: undefined,
  wrapperStyle: undefined,
  onBlur: undefined,
  onChange: undefined,
  error: undefined,
  type: 'text',
  component: Input,
  validate: undefined,
};

FormikInput.propTypes = {
  name: PropTypes.string.isRequired,
  hideIcons: PropTypes.bool,
  isValid: PropTypes.bool,
  placeholder: PropTypes.string,
  animateLabel: PropTypes.bool,
  inputStyle: PropTypes.object,
  labelStyle: PropTypes.object,
  wrapperStyle: PropTypes.object,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  error: PropTypes.string,
  type: PropTypes.string,
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.element,
  ]),
  validate: PropTypes.func,
};

export default FormikInput;

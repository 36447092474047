import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal';

import ClearCartConfirmation from '../../components/ClearCartConfirmation';
import withModal from '../../hocs/withModal';

const ClearCartModal = ({ visible, onCloseModal, onConfirm }) => (
  <Modal visible={visible}>
    <ClearCartConfirmation onConfirm={onConfirm} onCancel={onCloseModal} />
  </Modal>
);

ClearCartModal.defaultProps = {
  visible: false,
};

ClearCartModal.propTypes = {
  visible: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default withModal((props) => ({
  visible: props.visible,
}))(ClearCartModal);

import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import CircleButton from '../CircleButton';

import { ReactComponent as Close } from '../../assets/close.svg';
import withModal from '../../hocs/withModal';

import {
  productContent,
  productInnerWrapper,
  productOverlay,
  contentScroll,
} from '../ProductAdjustModal/ProductAdjustModal.styles';
import { productAdjustWrapper } from '../ProductAdjustMenu/ProductAdjustMenu.styles';

import { infoTextCss } from './OrderSpecials.styles';

const InfoTextModal = ({ visible, infoText, onClose }) => {
  return (
    <Modal
      visible={visible}
      styles={{
        innerWrapper: productInnerWrapper,
        content: productContent,
        overlay: productOverlay,
      }}
      keepMounted
    >
      <div css={productAdjustWrapper}>
        <div
          style={{
            width: '100%',
            paddingRight: '16px',
            paddingBottom: '16px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
          }}
        >
          <CircleButton
            icon={<Close />}
            size="30px"
            onClick={onClose}
            backgroundColor="primary"
            color="background"
            padding="3px"
          />
        </div>
        <div css={contentScroll}>
          <span css={infoTextCss}>{infoText}</span>
        </div>
      </div>
    </Modal>
  );
};

InfoTextModal.defaultProps = {
  visible: false,
};

InfoTextModal.propTypes = {
  visible: PropTypes.bool,
  infoText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withModal((props) => ({
  visible: props.visible,
}))(InfoTextModal);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Modal from '../Modal';
import CircleButton from '../CircleButton';
import Button from '../ButtonBit';
import Analytics from '../../utils/Analytics';
import {
  analyticsEventActions,
  analyticsEventCategory,
} from '../../utils/constants';
import ecommerceService from '../../services/ecommerce';
import gateway from '../../utils/gateway';
import showToast from '../../utils/showToast';
import reloadUser from '../../utils/reloadUser';
import { Creators as UserCreators } from '../../store/modules/user';
import { ReactComponent as Close } from '../../assets/close.svg';
import withModal from '../../hocs/withModal';
import {
  productContent,
  productInnerWrapper,
  productOverlay,
  container,
  menuHeader,
  menuTitle,
  contentScroll,
} from '../ProductAdjustModal/ProductAdjustModal.styles';
import DeliveryAddressSelect from '../DeliveryAddressSelect';
import UserAddressForm from '../UserAddressForm';

const mapStateToProps = ({
  productOrder: {
    productOrder: { store },
  },
}) => ({
  selectedStore: store,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setUser: UserCreators.setUser,
    },
    dispatch,
  );

const DeliveryAddressModal = ({
  visible,
  onClose,
  selectedStore,
  onFinish,
  setUser,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [addAddressMode, setAddAddressMode] = useState(false);
  const [addressesList, setAddressesList] = useState([]);

  const toggleAddAddressMode = () => setAddAddressMode((prev) => !prev);

  const fetchDeliveryAddresses = async () => {
    try {
      setIsLoading(true);

      const response = selectedStore?.id
        ? await gateway
            .get(ecommerceService.getAddressesWithDistances(selectedStore.id))
            .then(({ data }) => data)
        : await gateway
            .get(ecommerceService.getAddresses())
            .then(({ data }) => data);

      setAddressesList(response);
    } catch (err) {
      showToast(err?.response?.data?.message || err.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDeliveryAddresses();
  }, []);

  const handleAddressSelect = (newAddress) => {
    const adaptedAddress = {
      id: newAddress.id,
      label: newAddress.label,
      zipcode: newAddress.zipcode,
      street: newAddress.address,
      number: newAddress.number,
      complement: newAddress.address2,
      district: newAddress.district,
      city: newAddress.city,
      state: newAddress.state,
      receiverName: newAddress.receiverName,
      latitude: newAddress.latitude,
      longitude: newAddress.longitude,
      distance: newAddress.extra_distance,
      duration: newAddress.extra_duration,
      deliveryPrice: newAddress?.extra_shipping_price?.value,
    };

    onFinish(adaptedAddress);
  };

  const handleAddAddress = async (addressData, formikBag) => {
    try {
      formikBag.setSubmitting(true);

      const {
        addressLabel,
        addressReceiverName,
        addressZipcode,
        addressStreet,
        addressNumber,
        addressComplement,
        addressDistrict,
        addressCity,
        addressState,
        main,
      } = addressData;

      const payload = {
        label: addressLabel,
        receiverName: addressReceiverName,
        zipcode: addressZipcode,
        address: addressStreet,
        address2: addressComplement,
        number: addressNumber,
        district: addressDistrict,
        state: addressState,
        city: addressCity,
        main,
      };

      await gateway.post('/profile/address', payload);
      Analytics.event({
        category: analyticsEventCategory.CUSTOMER,
        action: analyticsEventActions.ADD_ADDRESS,
        label: 'Endereço adicionado',
      });

      showToast(t('addressesListPage:addressAddSuccess'), 'success');
      reloadUser(setUser);

      setAddAddressMode(false);
      fetchDeliveryAddresses();
    } catch (err) {
      console.error(err);
      showToast(err?.response?.data?.message || err.message, 'error');
      formikBag.setSubmitting(false);
    }
  };

  return (
    <Modal
      visible={visible}
      styles={{
        innerWrapper: productInnerWrapper,
        content: productContent,
        overlay: productOverlay,
      }}
      keepMounted
    >
      <div css={container}>
        <div css={menuHeader}>
          <div style={{ width: '30px' }} />
          <span css={menuTitle}>{t('orderReview:addressSelect')}</span>
          <CircleButton
            icon={<Close />}
            size="30px"
            onClick={onClose}
            backgroundColor="primary"
            color="background"
            padding="3px"
          />
        </div>
        {addAddressMode ? (
          <div css={contentScroll}>
            <UserAddressForm
              onFormSubmit={handleAddAddress}
              onCancel={toggleAddAddressMode}
            />
          </div>
        ) : (
          <>
            <div css={contentScroll}>
              <DeliveryAddressSelect
                addressesList={addressesList || []}
                onSelect={handleAddressSelect}
                isLoading={isLoading}
                disabled={isLoading}
              />
            </div>
            <div style={{ width: '100%', padding: '10px' }}>
              <Button color="primary" block onClick={toggleAddAddressMode}>
                {t('orderReview:add')}
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

DeliveryAddressModal.defaultProps = {
  selectedStore: null,
};

DeliveryAddressModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedStore: PropTypes.object,
  onFinish: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
};

const enhance = compose(
  withModal((props) => ({
    visible: props.visible,
  })),
  connect(mapStateToProps, mapDispatchToProps),
);

export default enhance(DeliveryAddressModal);

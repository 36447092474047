import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal';

import showToast from '../../utils/showToast';
import withModal from '../../hocs/withModal';
import dayjs from '../../utils/dayjs';
import gateway from '../../utils/gateway';
import SchedulesService from '../../services/schedules';
import DeliveryScheduleMenu from '../../components/DeliveryScheduleMenu';

import { deliveryScheduleModalStyles } from './OrderReviewPage.styles';

const getInitialScheduleSearch = (initialSelectedSchedule) => {
  if (!initialSelectedSchedule) {
    return Date.now();
  }
  const utcOffset = dayjs(initialSelectedSchedule.start).utcOffset();
  return dayjs(initialSelectedSchedule.start).add(utcOffset, 'minute');
};

const DeliveryScheduleModal = ({
  eventPointId,
  visible,
  onCloseModal,
  onScheduleDayConfirm,
  storeSchedule,
  initialSelectedSchedule,
}) => {
  const { scheduleMaxDays, scheduleSlotMaxOrders } = storeSchedule;
  const [isLoading, setIsLoading] = useState(false);
  const [pointSchedule, setPointSchedule] = useState(null);
  const [scheduleSearchDay, setScheduleSearchDay] = useState(
    getInitialScheduleSearch(initialSelectedSchedule),
  );
  const [selectedSchedule, setSelectedSchedule] = useState(
    initialSelectedSchedule || null,
  );

  const availableSearchDays = useMemo(
    () =>
      new Array(scheduleMaxDays + 1)
        .fill(true)
        .map((_, index) => dayjs(Date.now()).add(index, 'day')),
    [scheduleMaxDays],
  );

  const handleFetchPointSlotsFetch = async () => {
    try {
      setIsLoading(true);
      setPointSchedule(null);

      const response = await gateway
        .get(
          SchedulesService.getSlots(
            eventPointId,
            dayjs(scheduleSearchDay).toISOString(),
          ),
        )
        .then(({ data }) => data);

      setSelectedSchedule(
        response.slots.find(({ start }) => start === selectedSchedule?.start) ||
          null,
      );
      setPointSchedule(response);
    } catch (error) {
      setPointSchedule(null);
      showToast(error.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleFetchPointSlotsFetch();
  }, [scheduleSearchDay]);

  const handleSearchDayChange = (newSearchDay) => {
    setScheduleSearchDay(newSearchDay);
    setSelectedSchedule(null);
  };

  const handleNewSelectedSchedule = (newSelectedSchedule) => {
    setSelectedSchedule(newSelectedSchedule);
  };

  const handleConfirm = () => {
    onScheduleDayConfirm(selectedSchedule);
  };

  return (
    <Modal visible={visible} styles={deliveryScheduleModalStyles}>
      <DeliveryScheduleMenu
        availableSearchDays={availableSearchDays}
        availableSlots={pointSchedule?.slots}
        scheduleSlotMaxOrders={scheduleSlotMaxOrders}
        scheduleSearchDay={scheduleSearchDay}
        onSearchDayChange={handleSearchDayChange}
        selectedSchedule={selectedSchedule}
        onNewSelectedSchedule={handleNewSelectedSchedule}
        onCofirm={handleConfirm}
        onCancel={onCloseModal}
        isLoading={isLoading}
      />
    </Modal>
  );
};

DeliveryScheduleModal.defaultProps = {
  visible: false,
  initialSelectedSchedule: null,
};

DeliveryScheduleModal.propTypes = {
  eventPointId: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
  onScheduleDayConfirm: PropTypes.func.isRequired,
  initialSelectedSchedule: PropTypes.object,
  storeSchedule: PropTypes.shape({
    scheduleMaxDays: PropTypes.number,
    scheduleNextSlotOffset: PropTypes.number,
    scheduleSlotMaxOrders: PropTypes.number,
    scheduleSlotPeriod: PropTypes.number,
  }).isRequired,
};

export default withModal((props) => ({
  visible: props.visible,
}))(DeliveryScheduleModal);

import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { withFormik } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import Button from '../../components/ButtonBit';

import CreditCardModal from '../../components/CreditCardModal';
import InstallmentsModal from '../../components/InstallmentsModal';
import Input from '../../components/FormikInput';
import OrderReviewCartFooter from '../../components/OrderReviewCartFooter';
import {
  shippingTypes,
  smallMeasureTypesMap,
  paymentTypes,
  productTypes,
} from '../../utils/constants';
import localizePrice from '../../utils/localizePrice';
import getCashbackValue from '../../utils/getCashbackValue';
import Header from '../../components/Header';
import ScrollableView from '../../components/ScrollableView';
import FormikRowRadioButton from '../../components/FormikRowRadioButton';
import ProductReviewItem from '../../components/ProductReviewItem';
import ComboReviewItem from '../../components/ComboReviewItem';
import CreditCardItemSelect from '../../components/CreditCardItemSelect';
import Accordion from '../../components/Accordion/Accordion';
import AccordionGroup from '../../components/AccordionGroup';
import CurrencyInput from '../../components/CurrencyInput';
import { promotionalRow } from '../OrderCartPage/OrderCartPage.styles';
import { screenWrapper } from '../Page.styles';
import LoadingModal from '../../components/LoadingModal';

import orderReviewFormSchema from './orderReviewFormSchema';
import OrderReviewDiscounts from './OrderReviewDiscounts';
import OrderReviewPriceRelations from './OrderReviewPriceRelations';
import OrderReviewSchedule from './OrderReviewSchedule';
import {
  fieldTitle,
  tabWrapper,
  contentWrapper,
  changeExplanation,
  orderClearButtonWrapper,
  tabTitleWrapper,
  tabTitle,
} from './OrderReviewPage.styles';
import OrderReviewShipping from './OrderReviewShipping';
import OrderReviewComment from './OrderReviewComment';
import { phoneMask } from '../../utils/masks';

const mapStateToProps = ({
  tenantEyepass: {
    qrCodeOnly,
    deliveryDisabled,
    paymentOnDeliveryEnabled,
    paymentOnDeliveryOnly,
    maxInstallments,
    minInstallmentValue,
  },
  user,
}) => ({
  qrCodeOnly,
  deliveryDisabled,
  paymentOnDeliveryEnabled,
  paymentOnDeliveryOnly,
  maxInstallments,
  minInstallmentValue,
  user,
});

const OrderReviewScreen = ({
  deliveryPrice,
  onSidemenuClick,
  onProductAdjust,
  onProductRemove,
  onAddressChange,
  currentTag,
  currentAddress,
  onTagChange,
  orderProducts,
  totalPrice,
  values,
  errors,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  setFieldTouched,
  isValid,
  availableCreditCards,
  onOrderSchedule,
  onFormSave,
  isTagLocked,
  isFetchingLoyalties,
  isTagOnlyDelivery,
  loyaltyCashback,
  onEmptyCart,
  storeSchedule,
  deliverySchedule,
  onClearSchedule,
  selectedStore,
  qrCodeOnly,
  paymentOnDeliveryEnabled,
  paymentOnDeliveryOnly,
  maxInstallments,
  minInstallmentValue,
  allowAnonymous,
  isPostPaid,
  requiredFields,
  captchaRef,
}) => {
  const { t } = useTranslation();
  const selectedCard = useMemo(() => {
    const card = availableCreditCards.find(
      ({ id }) => id === values.creditCardOption,
    );
    if (card) {
      return card;
    }

    if (availableCreditCards.length > 0) {
      return availableCreditCards[0];
    }

    return null;
  }, [values, availableCreditCards]);

  const [shownCreditCard, setShownCreditCard] = useState(selectedCard);
  const [creditCardModalOpen, setCreditCardModalOpen] = useState(false);
  const [installmentsModalOpen, setInstallmentsModalOpen] = useState(false);
  const [discounts, setDiscounts] = useState({ cashback: 0, voucher: 0 });

  const hasCreditCards = availableCreditCards.length > 0;

  const toggleCreditCardModal = () => setCreditCardModalOpen((prev) => !prev);

  const toggleInstallmentsModal = () =>
    setInstallmentsModalOpen((prev) => !prev);

  useEffect(() => {
    setFieldTouched('change', false);
    setFieldValue('change', 0);
  }, [values.paymentType]);

  const handleProductAdjust = (productItem) => () => {
    onProductAdjust(productItem);
  };

  const handleProductRemove = (productItem) => () =>
    onProductRemove(productItem);

  const handleSidemenuClick = () => {
    onFormSave(values);
    onSidemenuClick();
  };

  const handleNewInstallmentSelect = (newInstallments) => {
    setFieldValue('installments', newInstallments);
    setInstallmentsModalOpen(false);
  };

  const isFreeDelivery = useMemo(() => {
    if (selectedStore?.totalToFreeDelivery === 0) {
      return false;
    }

    return totalPrice >= selectedStore?.totalToFreeDelivery;
  }, [totalPrice, selectedStore, values.cashback]);

  const deliveryFee = useMemo(() => {
    if (isFreeDelivery) {
      return 0;
    }

    return values.shippingType === shippingTypes.ADDRESS ? deliveryPrice : 0;
  }, [values.shippingType, deliveryPrice, isFreeDelivery]);

  const finalPrice = totalPrice + deliveryFee;

  const hasGiftCardProduct = useMemo(() => {
    return !!orderProducts.find(
      (product) => product.type === productTypes.GIFT_CARD,
    );
  }, [orderProducts]);

  const hasOnlyGiftCardProduct = useMemo(() => {
    return hasGiftCardProduct && orderProducts.length === 1;
  }, [orderProducts, hasGiftCardProduct]);

  const priceToBePaid = useMemo(() => {
    let deductions = { cashback: 0, voucher: 0 };

    if (values.useCashback) {
      deductions = {
        ...deductions,
        cashback: getCashbackValue(
          finalPrice,
          loyaltyCashback?.availableCashback,
          values.voucher && values.voucher.total,
          loyaltyCashback?.maxCashbackPercentage,
        ),
      };
    }

    if (values.voucher) {
      deductions = { ...deductions, voucher: values.voucher.total };
    }

    const deductionsSum = Object.keys(deductions)
      .map((discount) => deductions[discount])
      .reduce((acc, curr) => {
        return acc + curr;
      }, 0);

    if (deductionsSum > finalPrice) {
      return 0;
    }
    setDiscounts(deductions);

    return finalPrice - deductionsSum;
  }, [isFreeDelivery, totalPrice, finalPrice, loyaltyCashback, values]);

  const renderProduct = (productItem) => {
    const {
      productOrderId,
      name,
      price,
      image,
      amount,
      measure,
      complementGroups,
      comboProductItems,
      source,
    } = productItem;

    const complementsTotal = productItem.complementsTotal || 0;

    const fullPriceProduct =
      measure === 'UNIT'
        ? (price + complementsTotal) * amount
        : price * amount * 0.1 + complementsTotal;

    const productQuantityDescription =
      measure === 'UNIT'
        ? `${amount}`
        : `${amount * 100} ${smallMeasureTypesMap[measure]}`;

    if (comboProductItems && comboProductItems.length > 0) {
      return (
        <ComboReviewItem
          key={`combo-item-${productOrderId}`}
          comboImgSrc={image}
          comboName={name}
          quantity={amount}
          quantityDescription={productQuantityDescription}
          comboPrice={`R$ ${localizePrice(fullPriceProduct)}`}
          onRemove={handleProductRemove(productItem)}
          onEdit={handleProductAdjust(productItem)}
          comboProductItems={comboProductItems}
        />
      );
    }

    return (
      <ProductReviewItem
        key={`item-${productOrderId}`}
        productImgSrc={image}
        productName={name}
        quantityDescription={productQuantityDescription}
        productPrice={`R$ ${localizePrice(fullPriceProduct)}`}
        onRemove={handleProductRemove(productItem)}
        onEdit={
          source !== 'highlight' ? handleProductAdjust(productItem) : null
        }
        complementGroups={complementGroups}
      />
    );
  };

  const renderCreditCardOptions = () => {
    if (!shownCreditCard) {
      return true;
    }

    const { id, number, brand } = shownCreditCard;

    return (
      <>
        <CreditCardItemSelect
          key={`cc-${id}`}
          cardNumber={number}
          brand={brand && brand.toLowerCase()}
          isSelected={
            values.creditCardOption === id &&
            values.paymentType === paymentTypes.CREDIT_CARD_ONLINE
          }
          onSelect={() => {
            setFieldValue('creditCardOption', id);
            setFieldValue('paymentType', paymentTypes.CREDIT_CARD_ONLINE);
          }}
        />
      </>
    );
  };

  const renderProducts = useMemo(() => {
    if (orderProducts.length === 0) {
      return <span>{t('orderReview:noProducts')}</span>;
    }

    return (
      <>
        {orderProducts.map(renderProduct)}
        <div css={orderClearButtonWrapper}>
          <Button color="error" variant="outlined" onClick={onEmptyCart}>
            {t('orderReview:removeProducts')}
          </Button>
        </div>
      </>
    );
  }, [orderProducts, onEmptyCart]);

  const handleOnlineOpen = () =>
    setFieldValue('paymentType', paymentTypes.CREDIT_CARD_ONLINE);

  const handleOnDeliveryOpen = () =>
    setFieldValue('paymentType', paymentTypes.CREDIT_CARD);

  const validateChange = (value) => {
    let errorMessage;

    if (Number(value) > 0 && Number(value) < finalPrice) {
      errorMessage = 'orderReview:invalidChange';
    }

    return errorMessage;
  };

  const renderPromotionalRow = () => {
    if (selectedStore?.totalToFreeDelivery) {
      const { cashback } = values;

      const calculatedPrice = cashback ? totalPrice - cashback : totalPrice;

      if (selectedStore.totalToFreeDelivery > calculatedPrice) {
        return (
          <div css={promotionalRow}>
            {t('promotions:totalRemainingToFreeDelivery', {
              remaining: localizePrice(
                selectedStore.totalToFreeDelivery - calculatedPrice,
              ),
            })}
          </div>
        );
      }
    }

    return null;
  };

  const handleCreditCardSelect = (newCard) => {
    setShownCreditCard(newCard);
    setFieldValue('creditCardOption', newCard.id);
    toggleCreditCardModal();
  };

  const orderSubmitDisabled =
    !isValid || isSubmitting || orderProducts.length === 0;

  const cardDisabled =
    values.useCashback &&
    finalPrice <= loyaltyCashback?.availableCashback &&
    loyaltyCashback?.maxCashbackPercentage === 100;

  const showInstallmentsOptions =
    !cardDisabled &&
    values.paymentType === paymentTypes.CREDIT_CARD_ONLINE &&
    maxInstallments > 1 &&
    finalPrice / 2 > minInstallmentValue;

  const totalDiscount = Math.max(
    0,
    Math.min(finalPrice, discounts.cashback + discounts.voucher || 0),
  );

  return (
    <>
      <LoadingModal visible={isSubmitting} />
      <CreditCardModal
        visible={creditCardModalOpen}
        onClose={toggleCreditCardModal}
        creditCardList={availableCreditCards}
        onCreditCardConfirm={handleCreditCardSelect}
        selectedCreditCardId={values.creditCardOption}
      />
      <InstallmentsModal
        visible={installmentsModalOpen}
        onClose={toggleInstallmentsModal}
        paymentValue={priceToBePaid}
        initialInstallment={values.installments}
        onInstallmentSelect={handleNewInstallmentSelect}
      />
      <div css={screenWrapper}>
        <Header
          title={t('orderReview:finishOrder')}
          onPrev={handleSidemenuClick}
          promotionalRow={renderPromotionalRow()}
        />
        <ScrollableView divCss={contentWrapper} hideScroll hasHeader>
          <div css={tabWrapper}>
            <AccordionGroup toggle>
              <Accordion text={t('orderReview:myOrder')}>
                {renderProducts}
              </Accordion>
            </AccordionGroup>
          </div>
          {storeSchedule && !hasOnlyGiftCardProduct && !isPostPaid && (
            <OrderReviewSchedule
              deliverySchedule={deliverySchedule}
              onOrderSchedule={onOrderSchedule}
              onClearSchedule={onClearSchedule}
            />
          )}
          {!hasOnlyGiftCardProduct && (
            <OrderReviewShipping
              isTagOnlyDelivery={isTagOnlyDelivery}
              currentAddress={currentAddress}
              deliveryPrice={deliveryPrice}
              currentTag={currentTag}
              isTagLocked={isTagLocked}
              onTagChange={onTagChange}
              onAddressChange={onAddressChange}
              selectedStore={selectedStore}
              setFieldValue={setFieldValue}
              isFreeDelivery={isFreeDelivery}
              allowAnonymous={allowAnonymous}
            />
          )}
          {!isPostPaid && (
            <OrderReviewDiscounts
              isFetching={isFetchingLoyalties}
              loyaltyCashback={loyaltyCashback}
              values={values}
              setFieldValue={setFieldValue}
              hasGiftCardProduct={hasGiftCardProduct}
              maxPercentCashback={loyaltyCashback?.maxCashbackPercentage}
              subtotal={totalPrice}
              deliveryPrice={deliveryPrice}
            />
          )}
          <OrderReviewPriceRelations
            totalPrice={totalPrice}
            finalPrice={finalPrice}
            deliveryFee={deliveryFee}
            useCashback={values.useCashback}
            discounts={discounts}
            voucher={values.voucher}
            priceToBePaid={priceToBePaid}
            isPostPaid={isPostPaid}
            currentTag={currentTag}
          />
          {allowAnonymous && (
            <>
              <div css={tabWrapper}>
                <div css={tabTitleWrapper}>
                  <span css={tabTitle}>{t('forms:name')}</span>
                </div>
                <Input
                  name="name"
                  label=""
                  variant="bordered"
                  borderColor="primary"
                  labelColor="primary"
                  error={!!errors.name}
                />
                <span css={changeExplanation}>{t(errors.name)}</span>
              </div>
              <div css={tabWrapper}>
                <div css={tabTitleWrapper}>
                  <span css={tabTitle}>{t('forms:phone')}</span>
                </div>
                <Input
                  name="phone"
                  label=""
                  mask={phoneMask}
                  inputMode="numeric"
                  pattern="[0-9]*"
                  variant="bordered"
                  borderColor="primary"
                  labelColor="primary"
                  error={!!errors.phone}
                  autoFocus
                />
                <span css={changeExplanation}>{t(errors.phone)}</span>
              </div>
            </>
          )}
          <div css={tabWrapper}>
            <div css={tabTitleWrapper}>
              <span css={tabTitle}>{t('forms:comment')}</span>
            </div>
            <Input name="comment" component={OrderReviewComment} />
            <span css={changeExplanation}>{t(errors.comment)}</span>
          </div>
          <div css={tabWrapper}>
            <ReCAPTCHA
              ref={captchaRef}
              sitekey={process.env.REACT_APP_SITE_KEY}
            />
          </div>
          {!isPostPaid && (
            <div css={tabWrapper}>
              <AccordionGroup initial={paymentOnDeliveryOnly ? 1 : 0}>
                {!paymentOnDeliveryOnly && (
                  <Accordion
                    text={t('orderReview:onlinePayment')}
                    onOpen={handleOnlineOpen}
                  >
                    <CreditCardItemSelect
                      cardNumber={t('orderReview:payWithPix')}
                      brand="pix"
                      isSelected={values.paymentType === paymentTypes.PIX}
                      onSelect={() =>
                        setFieldValue('paymentType', paymentTypes.PIX)
                      }
                    />
                    {hasCreditCards ? (
                      <>
                        {!cardDisabled && renderCreditCardOptions()}
                        <div style={{ marginTop: '10px' }}>
                          <Button
                            color="primary"
                            onClick={toggleCreditCardModal}
                          >
                            {t('orderReview:changeCard')}
                          </Button>
                        </div>
                      </>
                    ) : (
                      <>
                        <span>{t('orderReview:noAvailableCards')}</span>
                        <div style={{ marginTop: '10px' }}>
                          <Button
                            color="primary"
                            onClick={toggleCreditCardModal}
                          >
                            {t('orderReview:addNewCard')}
                          </Button>
                        </div>
                      </>
                    )}
                    {showInstallmentsOptions && (
                      <>
                        <span css={fieldTitle}>{t('forms:installments')}</span>
                        <div>
                          <Button
                            onClick={toggleInstallmentsModal}
                            color="primary"
                            block
                          >
                            {values.installments > 1
                              ? t('forms:installmentsPay', {
                                  installments: values.installments,
                                  installmentValue: localizePrice(
                                    priceToBePaid / values.installments,
                                  ),
                                })
                              : t('forms:singleInstallment', {
                                  installmentValue:
                                    localizePrice(priceToBePaid),
                                })}
                          </Button>
                        </div>
                      </>
                    )}
                  </Accordion>
                )}
                {!qrCodeOnly &&
                  paymentOnDeliveryEnabled &&
                  !hasOnlyGiftCardProduct && (
                    <Accordion
                      text={t('orderReview:paymentOnDelivery')}
                      onOpen={handleOnDeliveryOpen}
                    >
                      <FormikRowRadioButton
                        name="CREDIT_CARD"
                        values={values}
                        fieldKey="paymentType"
                        label={t('orderReview:creditCard')}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <FormikRowRadioButton
                        name="DEBIT_CARD"
                        values={values}
                        fieldKey="paymentType"
                        label={t('orderReview:debitCard')}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      <FormikRowRadioButton
                        name="CASH"
                        values={values}
                        fieldKey="paymentType"
                        label={t('orderReview:cash')}
                        setFieldValue={setFieldValue}
                        setFieldTouched={setFieldTouched}
                      />
                      {values.paymentType === paymentTypes.CASH && (
                        <>
                          <span css={changeExplanation}>
                            {t('orderReview:changeExplanation')}
                          </span>
                          <Input
                            name="change"
                            placeholder={t('forms:change')}
                            animateLabel
                            validate={validateChange}
                            component={CurrencyInput}
                            onChange={(e, value, rawValue) =>
                              setFieldValue('change', rawValue)
                            }
                            wrapperStyle={{
                              padding: '0 8px',
                            }}
                          />
                        </>
                      )}
                    </Accordion>
                  )}
              </AccordionGroup>
            </div>
          )}
        </ScrollableView>
        <OrderReviewCartFooter
          total={finalPrice}
          totalDiscount={totalDiscount}
          onButtonClick={handleSubmit}
          disabled={orderSubmitDisabled}
          isPostPaid={isPostPaid}
        />
      </div>
    </>
  );
};

OrderReviewScreen.defaultProps = {
  isTagLocked: false,
  isFetchingLoyalties: false,
  isTagOnlyDelivery: false,
  loyaltyCashback: null,
  deliverySchedule: null,
  selectedStore: undefined,
};

OrderReviewScreen.propTypes = {
  onSidemenuClick: PropTypes.func.isRequired,
  onProductAdjust: PropTypes.func.isRequired,
  currentTag: PropTypes.string.isRequired,
  currentAddress: PropTypes.object.isRequired,
  onAddressChange: PropTypes.func.isRequired,
  onTagChange: PropTypes.func.isRequired,
  onOrderSchedule: PropTypes.func.isRequired,
  orderProducts: PropTypes.array.isRequired,
  totalPrice: PropTypes.number.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onProductRemove: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  availableCreditCards: PropTypes.array.isRequired,
  onFormSave: PropTypes.func.isRequired,
  isTagLocked: PropTypes.bool,
  deliveryPrice: PropTypes.number.isRequired,
  isFetchingLoyalties: PropTypes.bool,
  isTagOnlyDelivery: PropTypes.bool,
  loyaltyCashback: PropTypes.object,
  onEmptyCart: PropTypes.func.isRequired,
  onClearSchedule: PropTypes.func.isRequired,
  storeSchedule: PropTypes.shape({
    scheduleMaxDays: PropTypes.number,
    scheduleNextSlotOffset: PropTypes.number,
    scheduleSlotMaxOrders: PropTypes.number,
    scheduleSlotPeriod: PropTypes.number,
  }).isRequired,
  deliverySchedule: PropTypes.object,
  selectedStore: PropTypes.object,
  qrCodeOnly: PropTypes.bool.isRequired,
  paymentOnDeliveryEnabled: PropTypes.bool.isRequired,
  paymentOnDeliveryOnly: PropTypes.bool.isRequired,
  maxInstallments: PropTypes.number.isRequired,
  minInstallmentValue: PropTypes.number.isRequired,
  allowAnonymous: PropTypes.bool.isRequired,
  isPostPaid: PropTypes.bool.isRequired,
  requiredFields: PropTypes.array.isRequired,
  captchaRef: PropTypes.func.isRequired,
};

const getInitialCreditCardOption = (creditCardOption, availableCreditCards) => {
  if (creditCardOption) {
    return creditCardOption;
  }

  if (availableCreditCards.length >= 1) {
    const mainCard =
      availableCreditCards.find(({ main }) => main) || availableCreditCards[0];

    return mainCard.id;
  }

  return '';
};

const formikEnhance = withFormik({
  mapPropsToValues: ({ initialValues, availableCreditCards, user }) => ({
    shippingType: initialValues?.shippingType,
    paymentType: paymentTypes.CREDIT_CARD_ONLINE,
    creditCardOption: getInitialCreditCardOption(
      initialValues?.creditCardOption,
      availableCreditCards,
    ),
    useCashback: false,
    change: 0,
    cashback: null,
    installments: initialValues?.installments || 1,
    comment: initialValues?.comment || '',
    name: user?.name || '',
    phone: user?.phone || '',
  }),
  validationSchema: orderReviewFormSchema,
  handleSubmit: (values, { props, dirty, ...formikBag }) => {
    const { onFormSubmit, onFormSave } = props;
    onFormSave(values);
    onFormSubmit(values, formikBag);
  },
  enableReinitialize: true,
});

const enhancedForm = formikEnhance(OrderReviewScreen);

enhancedForm.propTypes = {
  initialValues: PropTypes.shape({
    shippingType: PropTypes.string,
    creditCardOption: PropTypes.string,
  }).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(enhancedForm);

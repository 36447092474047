import React from 'react';
import * as PropTypes from 'prop-types';
import Input from '../EyeInput';

const currencyFormatter = new global.Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const decimalFormatter = new global.Intl.NumberFormat('pt-BR', {
  currency: 'BRL',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const CurrencyInput = ({
  value,
  onChange,
  hidePlaceholder,
  hidePrefix,
  ...props
}) => {
  const handleChange = (e) => {
    e.preventDefault();

    let rawValue;
    if (e.target.value.length === 1) {
      rawValue = Number(e.target.value) / 100;
    } else {
      rawValue = Number(e.target.value.replace(/[^0-9-]/g, '')) / 100;
    }

    if (onChange) {
      onChange(e, e.target.value, rawValue);
    }
  };

  const formatter = hidePrefix ? decimalFormatter : currencyFormatter;

  const inputValue =
    hidePlaceholder && value === 0 ? '' : formatter.format(Number(value) || 0);

  return <Input {...props} value={inputValue} onChange={handleChange} />;
};

CurrencyInput.defaultProps = {
  onChange: undefined,
  hidePlaceholder: false,
  hidePrefix: false,
};

CurrencyInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  hidePlaceholder: PropTypes.bool,
  hidePrefix: PropTypes.bool,
};

export default CurrencyInput;

import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import useDebounce from '../useDebounce';

import TabsHeader from './TabsHeader';
import { TabsWrapper, TabsPanels, TabPanel } from './Tabs.styles';

const Tabs = ({
  headers,
  children,
  onTabEnter,
  onHeaderClick,
  containerId,
  headerId,
}) => {
  const arrLength = headers.length;
  const [currentTab, setCurrentTab] = useState(0);
  const debouncedCurrentTab = useDebounce(currentTab, 1000);

  const handleHeaderClick = (headerIndex) => (e) => {
    if (onHeaderClick) {
      onHeaderClick(headerIndex);
    }
  };

  const getRelativePos = (el) => {
    const pPos = el.parentNode.getBoundingClientRect(); // parent pos
    const cPos = el.getBoundingClientRect(); // target pos
    const pos = {
      top: cPos.top - pPos.top + el.parentNode.scrollTop,
      right: cPos.right - pPos.right,
      bottom: cPos.bottom - pPos.bottom,
      left: cPos.left - pPos.left,
      center: cPos.left - cPos.width - pPos.left,
    };

    return pos;
  };

  const handleActiveTabFocus = () => {
    const activeTabEl = document.querySelectorAll(`#${headerId} a`)[
      debouncedCurrentTab
    ];
    const pos = getRelativePos(activeTabEl);
    activeTabEl.parentNode.scrollBy({
      left: pos.center,
      behavior: 'smooth',
    });
  };

  const handleCurrentTabChange = (tabIndex) => () => {
    if (onTabEnter) {
      onTabEnter(tabIndex);
    }

    setCurrentTab(tabIndex);
  };

  useEffect(() => {
    handleActiveTabFocus();
  }, [debouncedCurrentTab]);

  const panelsList = useMemo(() => {
    return React.Children.map(children, (child, index) => {
      return (
        <Element name={`tab-${index}`} style={{ width: '100%' }}>
          <TabPanel>{child}</TabPanel>
        </Element>
      );
    });
  }, [children]);

  return (
    <TabsWrapper>
      {arrLength > 0 && (
        <TabsHeader
          headers={headers}
          onHeaderClick={handleHeaderClick}
          currentTab={currentTab}
          handleCurrentTabChange={handleCurrentTabChange}
          containerId={containerId}
        />
      )}
      <TabsPanels>{panelsList}</TabsPanels>
    </TabsWrapper>
  );
};

Tabs.defaultProps = {
  headers: [],
  onTabEnter: null,
  onHeaderClick: null,
  containerId: undefined,
  headerId: 'tabs__header',
};

Tabs.propTypes = {
  headers: PropTypes.array,
  onTabEnter: PropTypes.func,
  onHeaderClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  containerId: PropTypes.string,
  headerId: PropTypes.string,
};

export default Tabs;

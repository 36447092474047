import { css } from '@emotion/core';
import styled from '@emotion/styled';
import colors from '../constants';

const inputBorderColor = ({
  theme,
  hasError,
  valid,
  disabled,
  borderColor,
}) => {
  if (disabled) {
    return (theme && theme.getColor && theme.getColor('disabled')) || '#d4d4d4';
  }

  if (hasError) {
    return (theme && theme.getColor && theme.getColor('error')) || colors.error;
  }

  if (valid) {
    return (
      (theme && theme.getColor && theme.getColor('success')) || colors.success
    );
  }

  if (borderColor) {
    return (
      (theme && theme.getColor && theme.getColor(borderColor)) || borderColor
    );
  }

  return (theme && theme.getColor && theme.getColor('primary')) || '#a9a9a9';
};

const inputTextColor = ({ theme, color }) => {
  if (color) {
    return (theme && theme.getColor && theme.getColor(color)) || color;
  }

  return (theme && theme.getColor && theme.getColor('text')) || colors.graytext;
};

const getPaddingLeft = ({ hasIcon, variant }) => {
  if (hasIcon) {
    return '41px';
  }

  if (variant === 'bordered') {
    return '16px';
  }

  return '5px';
};

const getPaddingRight = ({ hasRightIcon, variant }) => {
  if (hasRightIcon) {
    return '41px';
  }

  if (variant === 'bordered') {
    return '16px';
  }

  return '5px';
};

const labelColor = ({ theme, disabled, color }) => {
  if (disabled) {
    return (theme && theme.getColor && theme.getColor('disabled')) || '#d4d4d4';
  }

  if (color) {
    return (theme && theme.getColor && theme.getColor(color)) || color;
  }

  return (theme && theme.getColor && theme.getColor('primary')) || '#8e8e8e';
};

const variants = {
  default: {
    borderBottom: '1px solid',
  },
  bordered: {
    border: '1px solid',
    borderRadius: '19px',
  },
};

export const wrapperCss = css`
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 8px;
  * {
    box-sizing: border-box;
  }
`;

export const iconCss = css`
  position: absolute;
  top: 50%;
  left: 12px;
  display: block;
  flex-shrink: 0;
  object-fit: contain;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
`;

export const rightIconCss = css`
  position: absolute;
  top: 50%;
  right: 12px;
  display: block;
  flex-shrink: 0;
  object-fit: contain;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledInput = styled.input`
  font-family: ${({ theme }) =>
    (theme && theme.getFont && theme.getFont()) ||
    'Arial, Helvetica, sans-serif'};
  font-size: ${({ theme }) =>
    (theme && theme.getFontSize && theme.getFontSize(1.15, 'px')) || '16px'};
  width: 100%;
  border: none;
  color: ${inputTextColor};
  height: 35px;
  padding-left: ${({ hasIcon, variant }) =>
    getPaddingLeft({ hasIcon, variant })};
  padding-right: ${({ hasRightIcon, variant }) =>
    getPaddingRight({ hasRightIcon, variant })};
  background-color: ${({ disabled }) =>
    disabled ? 'rgba(0, 0, 0, 0.05)' : 'transparent'};
  outline: none;
  ${({ variant }) => variants[variant]}
  border-color: ${inputBorderColor};
  resize: none;
`;

export const errorContainerCss = (theme) => css`
  color: ${(theme && theme.getColor && theme.getColor('error')) || '#ff4f65'};
  font-size: ${(theme && theme.getFontSize && theme.getFontSize(1, 'px')) ||
  '14px'};
  position: absolute;
  padding-left: 5px;
  padding-top: 5px;
`;

export const InputLabel = styled.label`
  font-family: ${({ theme }) =>
    (theme && theme.getFont && theme.getFont()) ||
    'Futura, Arial, Helvetica, sans-serif'};
  font-size: 100%;
  color: ${labelColor};
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(12px, -50%);
  transition: transform 0.2s, font-size 0.2s;
  cursor: text;
  z-index: 10;

  padding-left: 4px;
  padding-right: 4px;
  border-radius: 10px;
  background-color: ${({ theme, backgroundColor }) =>
    (backgroundColor &&
      theme &&
      theme.getColor &&
      theme.getColor(backgroundColor)) ||
    '#ffffff'};

  ${({ animated }) =>
    animated &&
    'font-size: 10px; transform: translate(12px, calc(-50% - 20px));'}
`;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useTheme } from 'emotion-theming';
import CircleButton from '../CircleButton';
import Button from '../ButtonBit';

import RemoveIcon from '../../assets/remove_icon.svg';
import AddIcon from '../../assets/add_icon.svg';

import {
  FooterContainer,
  footerButtonsWrapper,
  amountTextCss,
} from './ProductAdjustMenu.styles';

const mapStateToProps = ({
  productOrder: {
    productOrder: { store },
  },
}) => ({
  menuOnly: Boolean(store) && store.isMenuOnly,
});

const ProductAdjustFooter = ({
  shouldRemove,
  disabled,
  onConfirm,
  onSubtract,
  onAdd,
  priceText,
  amountText,
  menuOnly,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const buttonStyle = { fontSize: theme && theme.getFontSize(0.8, 'px') };

  return (
    <FooterContainer>
      {!menuOnly && (
        <div css={footerButtonsWrapper}>
          <CircleButton
            src={RemoveIcon}
            size="30px"
            onClick={onSubtract}
            backgroundColor="error"
            padding="5px"
          />
          <span css={amountTextCss}>{amountText}</span>
          <CircleButton
            src={AddIcon}
            size="30px"
            onClick={onAdd}
            backgroundColor="success"
            padding="5px"
          />
        </div>
      )}
      {!menuOnly && (
        <Button
          disabled={disabled}
          onClick={onConfirm}
          color={shouldRemove ? 'error' : 'primary'}
          style={buttonStyle}
        >
          {shouldRemove
            ? t('formValidation:remove')
            : t('productOrder:addItem', { priceText })}
        </Button>
      )}
    </FooterContainer>
  );
};

ProductAdjustFooter.defaultProps = {
  disabled: false,
  shouldRemove: false,
};

ProductAdjustFooter.propTypes = {
  disabled: PropTypes.bool,
  shouldRemove: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onSubtract: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  amountText: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  priceText: PropTypes.string.isRequired,
  menuOnly: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(ProductAdjustFooter);

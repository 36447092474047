import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '../ButtonBit';

import { ReactComponent as ArrowDown } from '../../assets/arrow_down.svg';
import {
  dropdownWrapper,
  dropdownMenu,
  optionCss,
} from './DropdownMenu.styles';

const DropdownMenu = ({
  id,
  buttonColor,
  buttonVariant,
  label,
  options,
  onOptionSelect,
  disabled,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const toggleDropdown = () => setOpen((prevOpen) => !prevOpen);

  const handleOptionClick = (optionId) => () => {
    toggleDropdown();
    onOptionSelect(optionId);
  };

  const renderOption = (option) => (
    <div
      key={option.id}
      css={optionCss}
      onClick={handleOptionClick(option.id)}
      onKeyDown={handleOptionClick(option.id)}
      type="button"
      aria-label="dropdown-input"
      role="presentation"
    >
      {option.label}
    </div>
  );

  return (
    <div id={id} css={dropdownWrapper} ref={ref}>
      <Button
        variant={buttonVariant}
        color={buttonColor}
        onClick={toggleDropdown}
        endIcon={<ArrowDown />}
        block
        disabled={disabled}
      >
        {label}
      </Button>
      {open && <div css={dropdownMenu}>{options.map(renderOption)}</div>}
    </div>
  );
};

DropdownMenu.defaultProps = {
  buttonColor: 'primary',
  buttonVariant: undefined,
  disabled: false,
};

DropdownMenu.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  buttonColor: PropTypes.string,
  buttonVariant: PropTypes.string,
  onOptionSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default DropdownMenu;

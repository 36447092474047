import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Input from '../EyeInput';
import Button from '../ButtonBit';

import localizePrice from '../../utils/localizePrice';
import CreditCardItemSelect from '../CreditCardItemSelect';
import { fieldPadding, rowFields, rowFieldPadding } from '../Form/Form.styles';
import {
  cpfMask,
  cnpjMask,
  creditCardMask,
  creditCardExpirationMask,
  creditCardCvvMask,
} from '../../utils/masks';
import { stripMask } from '../../utils/adaptField';

import { title } from './PaymentLinkForm.styles';

const mapStateToProps = ({
  tenantEyepass: { maxInstallments, minInstallmentValue },
}) => ({
  maxInstallments,
  minInstallmentValue,
});

const PaymentLinkForm = ({
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
  finalPrice,
  onInstallmentsChange,
  maxInstallments,
  minInstallmentValue,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div css={fieldPadding}>
        <Input
          value={values.document}
          touched={touched.document}
          isValid={!errors.document}
          error={errors.document && t(errors.document)}
          placeholder={t('forms:cpfOrCnpj')}
          animateLabel
          mask={(value) => {
            if (stripMask(value).length > 11) {
              return cnpjMask;
            }
            return cpfMask;
          }}
          name="document"
          onBlur={handleBlur}
          onChange={handleChange}
          disabled={values.hasDocument}
          inputMode="numeric"
          pattern="[0-9]*"
        />
      </div>
      <div css={fieldPadding}>
        <Input
          value={values.email}
          touched={touched.email}
          isValid={!errors.email}
          error={errors.email && t(errors.email)}
          placeholder={t('forms:email')}
          animateLabel
          name="email"
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </div>
      <div css={fieldPadding}>
        <CreditCardItemSelect
          cardNumber={t('orderReview:payWithPix')}
          brand="pix"
          isSelected={values.payLinkType === 'PIX'}
          onSelect={() => setFieldValue('payLinkType', 'PIX')}
        />
        <CreditCardItemSelect
          cardNumber={t('orderReview:payWithCreditCard')}
          isSelected={values.payLinkType === 'CREDIT_CARD'}
          onSelect={() => setFieldValue('payLinkType', 'CREDIT_CARD')}
        />
      </div>
      {values.payLinkType === 'CREDIT_CARD' && (
        <>
          <div css={fieldPadding}>
            <Input
              value={values.creditCardNumber}
              touched={touched.creditCardNumber}
              isValid={!errors.creditCardNumber}
              error={errors.creditCardNumber && t(errors.creditCardNumber)}
              placeholder={t('forms:creditCardNumber')}
              animateLabel
              mask={creditCardMask}
              name="creditCardNumber"
              onBlur={handleBlur}
              onChange={handleChange}
              guide={false}
              inputMode="numeric"
              pattern="[0-9]*"
            />
          </div>
          <div css={fieldPadding}>
            <Input
              value={values.creditCardHolderName}
              touched={touched.creditCardHolderName}
              isValid={!errors.creditCardHolderName}
              error={
                errors.creditCardHolderName && t(errors.creditCardHolderName)
              }
              placeholder={t('forms:creditCardHolderName')}
              animateLabel
              name="creditCardHolderName"
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
          <div css={rowFields}>
            <div css={rowFieldPadding}>
              <Input
                value={values.creditCardExpiration}
                touched={touched.creditCardExpiration}
                isValid={!errors.creditCardExpiration}
                error={
                  errors.creditCardExpiration && t(errors.creditCardExpiration)
                }
                placeholder={t('forms:creditCardExpiration')}
                animateLabel
                mask={creditCardExpirationMask}
                name="creditCardExpiration"
                onBlur={handleBlur}
                onChange={handleChange}
                inputMode="numeric"
                pattern="[0-9]*"
              />
            </div>
            <div css={rowFieldPadding}>
              <Input
                value={values.creditCardCvv}
                touched={touched.creditCardCvv}
                isValid={!errors.creditCardCvv}
                error={errors.creditCardCvv && t(errors.creditCardCvv)}
                placeholder={t('forms:creditCardCvv')}
                animateLabel
                mask={creditCardCvvMask}
                name="creditCardCvv"
                onBlur={handleBlur}
                onChange={handleChange}
                inputMode="numeric"
                guide={false}
                pattern="[0-9]*"
              />
            </div>
          </div>
          {maxInstallments > 1 && finalPrice / 2 > minInstallmentValue && (
            <>
              <span css={title}>{t('forms:installments')}</span>
              <div css={fieldPadding}>
                <Button onClick={onInstallmentsChange} color="primary" block>
                  {values.installments > 1
                    ? t('forms:installmentsPay', {
                        installments: values.installments,
                        installmentValue: localizePrice(
                          finalPrice / values.installments,
                        ),
                      })
                    : t('forms:singleInstallment', {
                        installmentValue: localizePrice(finalPrice),
                      })}
                </Button>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

PaymentLinkForm.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  finalPrice: PropTypes.number.isRequired,
  onInstallmentsChange: PropTypes.func.isRequired,
  maxInstallments: PropTypes.number.isRequired,
  minInstallmentValue: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(PaymentLinkForm);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CircleButton from '../CircleButton';

import shoppingBasket from '../../assets/shopping_basket.svg';
import AddIcon from '../../assets/add_icon.svg';

import {
  ItemContainer,
  ProductImage,
  ItemDescriptionWrapper,
  ProductItemText,
  ProductText,
  productDescriptionCss,
  amountTextCss,
} from '../ProductItem/ProductItem.styles';

const mapStateToProps = ({
  productOrder: {
    productOrder: { store },
  },
}) => ({
  menuOnly: Boolean(store) && store.isMenuOnly,
});

const ComboItem = ({
  comboImgSrc,
  comboName,
  comboValue,
  onClick,
  disabled,
  comboDescription,
  amountText,
  menuOnly,
}) => {
  const { t } = useTranslation();
  const [fallbackImg, setFallbackImg] = useState(null);

  const onImgError = () => setFallbackImg(shoppingBasket);

  return (
    <>
      <ItemContainer
        disabled={disabled}
        onClick={onClick}
        role="presentation"
        onKeyDown={onClick}
      >
        <div style={{ position: 'relative' }}>
          <ProductImage
            src={fallbackImg || comboImgSrc || shoppingBasket}
            onError={onImgError}
            alt={`img-${comboName}`}
            resizemode="contain"
          />
          {amountText && <span css={amountTextCss}>{amountText}</span>}
        </div>
        <ItemDescriptionWrapper>
          <ProductItemText>{comboName}</ProductItemText>
          {comboDescription && (
            <div css={productDescriptionCss}>
              <ProductText fontSize={0.85}>{comboDescription}</ProductText>
            </div>
          )}
          <ProductText
            fontSize={1}
            customColor="placeholder"
            fontFamily="futura"
          >
            {comboValue}
          </ProductText>
          {disabled && (
            <ProductItemText>
              {t('productOrder:soldOutProduct')}
            </ProductItemText>
          )}
        </ItemDescriptionWrapper>
        <div
          style={{ display: 'flex', height: '100%', flexDirection: 'column' }}
        >
          {!disabled && !menuOnly && (
            <CircleButton src={AddIcon} size="24px" backgroundColor="success" />
          )}
        </div>
      </ItemContainer>
    </>
  );
};

ComboItem.defaultProps = {
  comboImgSrc: null,
  disabled: false,
  comboDescription: '',
  amountText: null,
};

ComboItem.propTypes = {
  comboImgSrc: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  comboName: PropTypes.string.isRequired,
  comboValue: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  comboDescription: PropTypes.string,
  amountText: PropTypes.string,
  menuOnly: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(ComboItem);

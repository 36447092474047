import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-text-mask';
import composeRefs from '@seznam/compose-react-refs';
import {
  wrapperCss,
  InputWrapper,
  StyledInput,
  errorContainerCss,
  InputLabel,
  iconCss,
  rightIconCss,
} from './EyeInput.styles';
import ValidationIcon from './ValidationIcon';

const EyeInput = ({
  value,
  onChange,
  onBlur,
  onFocus,
  name,
  disabled,
  error,
  isValid,
  touched,
  mask,
  errorClassName,
  errorStyle,
  inputIcon,
  inputRightIcon,
  hideIcons,
  iconOffset,
  hideErrors,
  wrapperStyle,
  containerClassName,
  className,
  inputWrapperStyle,
  inputStyle,
  labelStyle,
  placeholder,
  animateLabel,
  required,
  onKeyDown,
  onClick,
  type,
  inputMode,
  pattern,
  guide,
  variant,
  color,
  labelColor,
  borderColor,
  labelBackgroundColor,
  components,
  isMultiline,
  inputCss,
}) => {
  const [animated, setAnimated] = useState(false);
  const inputRef = useRef(null);

  const shouldShowError = () => name && !hideErrors && error && touched;

  const handleLabelClick = (e) => {
    if (
      inputRef.current &&
      inputRef.current.focus &&
      typeof inputRef.current.focus === 'function'
    ) {
      inputRef.current.focus();
    }
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  const handleFocus = (e) => {
    if (onFocus) {
      onFocus(e);
    }
    setAnimated(true);
  };

  const handleBlur = (e) => {
    if (onBlur) {
      onBlur(e);
    }
    if (!value) {
      setAnimated(false);
    }
  };

  const handleChange = (e) => {
    if (onChange) onChange(e);
  };

  useEffect(() => {
    if (value) {
      setAnimated(true);
    } else {
      setAnimated(false);
    }
  }, [value]);

  const renderError = () => {
    return components.Error ? (
      components.Error(error)
    ) : (
      <div
        css={errorContainerCss}
        className={errorClassName}
        style={errorStyle}
      >
        {error}
      </div>
    );
  };

  const renderInput = (ref, props) => {
    const maskedProps = props;
    const {
      className: maskedClassName,
      disabled: maskedDisabled,
      name: maskedName,
      onBlur: maskedOnBlur,
      onChange: maskedOnChange,
      onFocus: maskedOnFocus,
      placeholder: maskedPlaceholder,
      'aria-label': maskedAriaLabel,
      id: maskedId,
    } = maskedProps;

    return (
      <StyledInput
        ref={composeRefs(inputRef, ref)}
        id={maskedId}
        aria-label={maskedAriaLabel}
        name={maskedName}
        value={value}
        className={maskedClassName}
        disabled={maskedDisabled}
        placeholder={maskedPlaceholder}
        onChange={maskedOnChange}
        onFocus={maskedOnFocus}
        onBlur={maskedOnBlur}
        hasError={!!error}
        valid={isValid}
        inputMode={inputMode}
        pattern={pattern}
        style={inputStyle}
        hasIcon={!!inputIcon}
        variant={variant}
        color={color}
        borderColor={borderColor}
        css={inputCss}
      />
    );
  };

  const renderIcon = () => {
    return inputIcon && <span css={iconCss}>{inputIcon}</span>;
  };

  const renderRightIcon = () => {
    return inputRightIcon && <span css={rightIconCss}>{inputRightIcon}</span>;
  };

  return (
    <div className={containerClassName} css={wrapperCss} style={wrapperStyle}>
      {renderIcon()}
      {renderRightIcon()}
      <InputWrapper style={inputWrapperStyle}>
        {animateLabel && (
          <InputLabel
            onClick={handleLabelClick}
            onKeyPress={handleLabelClick}
            htmlFor={`input-${name}`}
            style={labelStyle}
            disabled={disabled}
            animated={animated}
            variant={variant}
            color={labelColor}
            backgroundColor={labelBackgroundColor}
          >
            {required ? placeholder.concat('*') : placeholder}
          </InputLabel>
        )}
        {mask ? (
          <InputMask
            id={`input-${name}`}
            aria-label={name}
            name={name}
            value={value}
            placeholder={!animateLabel ? placeholder : ''}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            onClick={onClick}
            ref={inputRef}
            disabled={disabled}
            className={className}
            mask={mask}
            maskChar={null}
            render={renderInput}
            type={type}
            guide={guide}
          />
        ) : (
          <StyledInput
            id={`input-${name}`}
            aria-label={name}
            name={name}
            value={value}
            placeholder={!animateLabel ? placeholder : ''}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            onClick={onClick}
            ref={inputRef}
            disabled={disabled}
            className={className}
            hasError={!!error}
            valid={isValid}
            type={type}
            inputMode={inputMode}
            pattern={pattern}
            style={inputStyle}
            hasIcon={!!inputIcon}
            hasRightIcon={!!inputRightIcon}
            variant={variant}
            color={color}
            borderColor={borderColor}
            as={isMultiline && 'textarea'}
            css={inputCss}
          />
        )}
        {!hideIcons && touched && (isValid || error) && (
          <ValidationIcon
            error={!!error}
            iconOffset={iconOffset}
            hasRightIcon={!!inputRightIcon}
          />
        )}
        {shouldShowError() && renderError()}
      </InputWrapper>
    </div>
  );
};

EyeInput.defaultProps = {
  animateLabel: false,
  value: '',
  onChange: undefined,
  onBlur: undefined,
  onFocus: undefined,
  disabled: false,
  hideIcons: true,
  hideErrors: false,
  inputIcon: undefined,
  inputRightIcon: undefined,
  iconOffset: undefined,
  wrapperStyle: undefined,
  inputWrapperStyle: undefined,
  containerClassName: undefined,
  className: undefined,
  error: null,
  errorStyle: undefined,
  errorClassName: undefined,
  inputStyle: undefined,
  labelStyle: undefined,
  placeholder: undefined,
  touched: false,
  required: false,
  isValid: false,
  name: '',
  mask: false,
  onKeyDown: undefined,
  onClick: undefined,
  type: 'text',
  inputMode: undefined,
  pattern: undefined,
  guide: true,
  variant: 'default',
  color: undefined,
  borderColor: undefined,
  labelBackgroundColor: undefined,
  labelColor: undefined,
  components: {},
  isMultiline: false,
  inputCss: undefined,
};

EyeInput.propTypes = {
  animateLabel: PropTypes.bool,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  errorClassName: PropTypes.string,
  errorStyle: PropTypes.object,
  hideErrors: PropTypes.bool,
  hideIcons: PropTypes.bool,
  iconOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputIcon: PropTypes.node,
  inputRightIcon: PropTypes.node,
  inputWrapperStyle: PropTypes.object,
  isValid: PropTypes.bool,
  labelStyle: PropTypes.object,
  inputStyle: PropTypes.object,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  touched: PropTypes.bool,
  value: PropTypes.string,
  wrapperStyle: PropTypes.object,
  mask: PropTypes.oneOfType([PropTypes.bool, PropTypes.array, PropTypes.func]),
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
  type: PropTypes.string,
  inputMode: PropTypes.string,
  pattern: PropTypes.string,
  guide: PropTypes.bool,
  variant: PropTypes.string,
  color: PropTypes.string,
  labelColor: PropTypes.string,
  borderColor: PropTypes.string,
  labelBackgroundColor: PropTypes.string,
  components: PropTypes.shape({
    Error: PropTypes.func,
  }),
  isMultiline: PropTypes.bool,
  inputCss: PropTypes.string,
};

export default EyeInput;

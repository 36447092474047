import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Tabs from '../../components/Tabs';
import Loading from '../../components/LoadingBit';

import Analytics from '../../utils/Analytics';
import localizePrice from '../../utils/localizePrice';
import OrderCartHeader from '../../components/OrderCartHeader';
import ScrollableView from '../../components/ScrollableView';
import CartFooter from '../../components/CartFooter';
import ProductGroup from '../../components/ProductGroup';
import OrderSpecials from '../../components/OrderSpecials';
import { screenWrapper } from '../Page.styles';

import OrderCartOptions from './OrderCartOptions';
import { noProductText, promotionalRow } from './OrderCartPage.styles';

const OrderCartScreen = ({
  onChangeStore,
  productGroups,
  onAddItem,
  totalPrice,
  selectedShippingType,
  onShippingTypeChange,
  isStoreLocked,
  isLogged,
  onLogin,
  onOrderFinish,
  isLoading,
  hasProducts,
  currentTag,
  submitDisabled,
  selectedStore,
  onQrCodeScan,
  orderProducts,
  currentAddress,
  onDeliveryAddressChange,
  searchText,
  onSearchTextChange,
  appliedFilters,
  onFiltersChange,
  onRemoveFilter,
  tagInfo,
}) => {
  const { t } = useTranslation();

  const { allowAnonymous = false } = tagInfo;

  const onFooterButtonClick =
    isLogged || allowAnonymous ? onOrderFinish : onLogin;

  const productGroupsTabs = useMemo(() => {
    if (!productGroups || productGroups.length === 0) {
      return ['carregando'];
    }

    const productGroupsNames = productGroups.map(
      (productGroup) => productGroup.name,
    );

    return productGroupsNames;
  }, [productGroups, t]);

  const handleFooterButtonClick = () => {
    Analytics.modalview('Modal de items do carrinho', '/carrinho/lista');
    onFooterButtonClick();
  };

  const handleFiltersChange = () => {
    Analytics.modalview('Modal de filtros', '/carrinho/filtros');
    onFiltersChange();
  };

  const handleAddItem = (productItem) => {
    Analytics.modalview('Modal de adição de item', '/carrinho/produto');
    onAddItem(productItem);
  };

  const handleShippingTypeChange = () => {
    Analytics.modalview(
      'Modal de forma de entrega',
      '/carrinho/forma-de-entrega',
    );
    onShippingTypeChange();
  };

  const handleChangeStore = () => {
    Analytics.modalview('Modal de lojas', '/carrinho/lojas');
    onChangeStore();
  };

  const renderPromotionalRow = () => {
    if (selectedStore?.totalToFreeDelivery) {
      return (
        <div css={promotionalRow}>
          {t('promotions:freeDelivery', {
            total: localizePrice(selectedStore.totalToFreeDelivery),
          })}
        </div>
      );
    }

    return true;
  };

  const renderProductGroup = (productGroup) => {
    const ignoreRigidStock = Boolean(selectedStore?.ignoreRigidStock);

    return (
      <ProductGroup
        key={`item-${productGroup.id}`}
        productGroup={productGroup}
        onAddItem={handleAddItem}
        ignoreRigidStock={ignoreRigidStock}
      />
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <Loading
          show
          center
          style={{ height: 'auto', flexGrow: 0, marginTop: 24 }}
        />
      );
    }

    if (!selectedStore) {
      return <div css={noProductText}>{t('productOrder:selectAStore')}</div>;
    }

    if (productGroups.length === 0) {
      return <div css={noProductText}>{t('productOrder:noProducts')}</div>;
    }

    return (
      <Tabs containerId="cart" headers={productGroupsTabs}>
        {productGroups.map(renderProductGroup)}
      </Tabs>
    );
  };

  return (
    <div css={screenWrapper}>
      <OrderCartHeader
        onReadNewQrCode={onQrCodeScan}
        deliveryAddress={currentAddress}
        onDeliveryAddressChange={onDeliveryAddressChange}
        deliveryAddressDisabled={!selectedStore}
        promotionalRow={renderPromotionalRow()}
      />
      <ScrollableView
        id="cart"
        hideScroll
        hasHeader
        hasFooter={!submitDisabled}
      >
        <OrderCartOptions
          selectedStore={selectedStore}
          selectedShippingType={selectedShippingType}
          onShippingTypeChange={handleShippingTypeChange}
          currentTag={currentTag}
          deliveryAddress={currentAddress}
          onOrderStoreChange={!isStoreLocked ? handleChangeStore : undefined}
          searchText={searchText}
          onSearchTextChange={onSearchTextChange}
          appliedFilters={appliedFilters}
          onFiltersChange={
            productGroups.length > 0 ? handleFiltersChange : undefined
          }
          onRemoveFilter={onRemoveFilter}
        />
        <OrderSpecials
          selectedStore={selectedStore}
          onChangeAddressAttempt={onDeliveryAddressChange}
        />
        {renderContent()}
        <div style={{ width: '100%', paddingBottom: '60px' }} />
      </ScrollableView>
      <CartFooter
        total={totalPrice}
        middleText={t('productOrder:showCart')}
        onButtonClick={handleFooterButtonClick}
        visible={hasProducts}
        cartCount={orderProducts.length}
      />
    </div>
  );
};

OrderCartScreen.defaultProps = {
  isLoading: false,
  submitDisabled: true,
  hasProducts: true,
  isStoreLocked: false,
  selectedStore: undefined,
  onQrCodeScan: null,
  selectedShippingType: null,
  currentAddress: null,
};

OrderCartScreen.propTypes = {
  onChangeStore: PropTypes.func.isRequired,
  productGroups: PropTypes.array.isRequired,
  onAddItem: PropTypes.func.isRequired,
  totalPrice: PropTypes.number.isRequired,
  isLogged: PropTypes.bool.isRequired,
  isStoreLocked: PropTypes.bool,
  onLogin: PropTypes.func.isRequired,
  onOrderFinish: PropTypes.func.isRequired,
  onShippingTypeChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  submitDisabled: PropTypes.bool,
  hasProducts: PropTypes.bool,
  selectedStore: PropTypes.object,
  onQrCodeScan: PropTypes.func,
  orderProducts: PropTypes.array.isRequired,
  currentTag: PropTypes.string.isRequired,
  selectedShippingType: PropTypes.string,
  currentAddress: PropTypes.object,
  onDeliveryAddressChange: PropTypes.func.isRequired,
  searchText: PropTypes.string.isRequired,
  onSearchTextChange: PropTypes.func.isRequired,
  appliedFilters: PropTypes.array.isRequired,
  onFiltersChange: PropTypes.func.isRequired,
  onRemoveFilter: PropTypes.func.isRequired,
  tagInfo: PropTypes.object.isRequired,
};

export default OrderCartScreen;

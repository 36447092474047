import styled from '@emotion/styled';
import facepaint from 'facepaint';

const mq = facepaint(['@media screen and (min-width: 375px)']);

const getButtonColor = (theme, colorKey, defaultColor = 'transparent') => {
  if (colorKey) {
    const themeColor = theme && theme.getColor && theme.getColor(colorKey);

    return themeColor || defaultColor;
  }

  return defaultColor;
};

const getSize = (size) => {
  const normalizedSize = Array.isArray(size) ? size : [size];

  return mq({
    width: normalizedSize,
    height: normalizedSize,
  });
};

export const MiniButton = styled.button`
  ${({ size }) => getSize(size)};
  border-radius: 50%;
  background-color: ${({ backgroundColor, theme }) =>
    getButtonColor(theme, backgroundColor)};
  border-color: ${({ borderColor, theme }) =>
    getButtonColor(theme, borderColor)};
  border-width: ${({ borderWidth }) => borderWidth};
  padding: ${({ padding }) => padding};
  outline: none;
  overflow: hidden;
  transition: opacity 0.3s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color, theme }) => getButtonColor(theme, color, 'inherit')};

  &:active {
    opacity: 0.8;
  }
`;

export const MiniButtonImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  flex-shrink: 0;
`;

import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import Loading from '../LoadingBit';

const LoadingScreen = ({ style, isSmall }) => {
  const theme = useTheme();
  const loadingColor = theme && theme.getColor('uiText');

  return (
    <Loading
      loadingColor={loadingColor}
      show
      center
      isSmall={isSmall}
      style={{
        height: 'auto',
        flexGrow: 0,
        ...style,
      }}
    />
  );
};

LoadingScreen.defaultProps = {
  style: undefined,
  isSmall: false,
};

LoadingScreen.propTypes = {
  style: PropTypes.object,
  isSmall: PropTypes.bool,
};

export default LoadingScreen;

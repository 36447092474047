import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/LoadingBit';
import Button from '../../components/ButtonBit';

import ScrollableView from '../../components/ScrollableView';
import Header from '../../components/Header';
import CustomerCardItem from '../../components/CustomerCardItem';
import { screenWrapper, screenInner } from '../Page.styles';
import { addButtonWrapper } from './CardsPage.styles';

const CardsPageScreen = ({
  isLoading,
  customerCards,
  onSetMainCard,
  onRemoveCard,
  onPrev,
  onAddCard,
}) => {
  const { t } = useTranslation();

  const renderCustomerCard = (customerCard) => {
    const { id, brand, main, last_digits: lastDigits } = customerCard;

    return (
      <CustomerCardItem
        key={id}
        brand={brand && brand.toLowerCase()}
        lastDigits={lastDigits}
        main={main}
        onRemove={() => onRemoveCard(id)}
        onTurnMain={() => onSetMainCard(id)}
      />
    );
  };

  const content = useMemo(() => {
    if (!customerCards || customerCards.length === 0) {
      return <span>{t('cardsPage:noRegisteredCards')}</span>;
    }

    return customerCards.map(renderCustomerCard);
  }, [customerCards]);

  return (
    <div css={screenWrapper}>
      <Header onPrev={onPrev} title={t('cardsPage:paymentMethods')} />
      <ScrollableView
        id="cart"
        hideScroll
        hasHeader
        hasFooter
        divCss={screenInner}
      >
        {isLoading ? (
          <Loading
            show
            center
            style={{ height: 'auto', flexGrow: 0, marginTop: 24 }}
          />
        ) : (
          content
        )}
      </ScrollableView>
      <div css={addButtonWrapper}>
        <Button block onClick={onAddCard} disabled={isLoading}>
          {t('cardsPage:add')}
        </Button>
      </div>
    </div>
  );
};

CardsPageScreen.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  customerCards: PropTypes.array.isRequired,
  onSetMainCard: PropTypes.func.isRequired,
  onRemoveCard: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  onAddCard: PropTypes.func.isRequired,
};

export default CardsPageScreen;

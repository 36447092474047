import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from '../../components/Modal';

import CommonConfirmation from '../../components/CommonConfirmation';
import withModal from '../../hocs/withModal';

const UserDeleteConfirmationModal = ({ visible, onCloseModal, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Modal visible={visible}>
      <CommonConfirmation
        confirmationText={t('warnings:userDataDelete')}
        onConfirm={onConfirm}
        onCancel={onCloseModal}
      />
    </Modal>
  );
};

UserDeleteConfirmationModal.defaultProps = {
  visible: false,
};

UserDeleteConfirmationModal.propTypes = {
  visible: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default withModal((props) => ({
  visible: props.visible,
}))(UserDeleteConfirmationModal);

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';
import colors from '../constants';
import IconCircleOutline from './IconCircleOutline';

const ValidationIcon = ({ error, iconOffset, hasRightIcon }) => {
  const theme = useTheme();

  const rightIconOffset = () => {
    if (hasRightIcon) {
      return 25;
    }

    return 0;
  };

  const iconPosition = useMemo(
    () => ({
      position: 'absolute',
      right: iconOffset + rightIconOffset(),
      top: '50%',
      transform: 'translateY(-50%)',
      width: 24,
      height: 24,
    }),
    [iconOffset, hasRightIcon],
  );

  if (error) {
    const closeColor =
      (theme && theme.getColor && theme.getColor('error')) || colors.error;

    return (
      <div style={iconPosition}>
        <IconCircleOutline color={closeColor} error />
      </div>
    );
  }

  const checkColor =
    (theme && theme.getColor && theme.getColor('success')) || colors.success;

  return (
    <div style={iconPosition}>
      <IconCircleOutline color={checkColor} />
    </div>
  );
};

ValidationIcon.defaultProps = {
  error: false,
  iconOffset: 8,
  hasRightIcon: false,
};

ValidationIcon.propTypes = {
  error: PropTypes.bool,
  hasRightIcon: PropTypes.bool,
  iconOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ValidationIcon;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from '../../components/Modal';

import CommonConfirmation from '../../components/CommonConfirmation';
import withModal from '../../hocs/withModal';

const ScheduleClearModal = ({ visible, onCloseModal, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Modal visible={visible}>
      <CommonConfirmation
        confirmationText={t('orderReview:confirmScheduleClear')}
        onConfirm={onConfirm}
        onCancel={onCloseModal}
        confirmText={t('navigation:cancel')}
        cancelText={t('navigation:back')}
      />
    </Modal>
  );
};

ScheduleClearModal.defaultProps = {
  visible: false,
};

ScheduleClearModal.propTypes = {
  visible: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default withModal((props) => ({
  visible: props.visible,
}))(ScheduleClearModal);

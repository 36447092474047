import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Document, Page, pdfjs } from 'react-pdf';
import ScrollContainer from 'react-indiana-drag-scroll';
import Modal from '../Modal';
import CircleButton from '../CircleButton';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import withModal from '../../hocs/withModal';
import TOSPdf from '../../assets/tos.pdf';
import { ReactComponent as KeyboardArrowDown } from '../../assets/keyboard_arrow_down.svg';
import { ReactComponent as ChevronLeft } from '../../assets/chevron_left.svg';
import { ReactComponent as ChevronRight } from '../../assets/chevron_right.svg';
import { ReactComponent as ZoomIn } from '../../assets/zoom-in.svg';
import { ReactComponent as ZoomOut } from '../../assets/zoom-out.svg';
import useVisualViewport from '../../utils/useVisualViewport';
import {
  productContent,
  productInnerWrapper,
} from '../ProductAdjustModal/ProductAdjustModal.styles';
import { productAdjustWrapper } from '../ProductAdjustMenu/ProductAdjustMenu.styles';

import {
  contentWrapper,
  FooterContainer,
  footerText,
} from './PdfViewerModal.styles';

pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js';

const mapStateToProps = ({ tenantEyepass: { tosUrl } }) => ({
  tosUrl,
});

const PdfViewerModal = ({ visible, tosUrl, onCloseModal }) => {
  const { maxWidth } = useVisualViewport();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isZoomed, setIsZoomed] = useState(false);

  const pdfWidth = useMemo(() => {
    const multiplier = isZoomed ? 3 : 1;
    return String(Math.min(454 * multiplier, maxWidth * multiplier - 26));
  }, [maxWidth, isZoomed]);

  const tosFile = useMemo(() => tosUrl || TOSPdf, [tosUrl]);

  const toggleZoom = () => setIsZoomed((prev) => !prev);

  const onDocumentLoadSuccess = ({ numPages: newNumPages }) => {
    setNumPages(newNumPages);
  };

  const handleNextPage = () => {
    setIsZoomed(false);
    setPageNumber((prev) => Math.min(prev + 1, numPages));
  };

  const handlePrevPage = () => {
    setIsZoomed(false);
    setPageNumber((prev) => Math.max(prev - 1, 1));
  };

  return (
    <Modal
      visible={visible}
      styles={{
        innerWrapper: productInnerWrapper,
        content: productContent,
        overlay: { padding: 0 },
      }}
      keepMounted
    >
      <div css={productAdjustWrapper}>
        <div
          style={{
            width: '100%',
            paddingRight: '8px',
            paddingLeft: '8px',
            paddingBottom: '5px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <CircleButton
            icon={isZoomed ? <ZoomOut /> : <ZoomIn />}
            size="30px"
            onClick={toggleZoom}
            backgroundColor="background"
            color="background"
            padding="3px"
          />
          <CircleButton
            icon={<KeyboardArrowDown />}
            size="30px"
            onClick={onCloseModal}
            backgroundColor="primary"
            color="background"
            padding="3px"
          />
        </div>
        <ScrollContainer css={contentWrapper}>
          <Document
            file={{ url: tosFile }}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} width={pdfWidth} />
          </Document>
        </ScrollContainer>
        <FooterContainer>
          {pageNumber === 1 ? (
            <div style={{ width: '30px' }} />
          ) : (
            <CircleButton
              icon={<ChevronLeft />}
              size="30px"
              onClick={handlePrevPage}
              backgroundColor="primary"
              color="background"
              padding="5px"
            />
          )}
          <span css={footerText}>{pageNumber}</span>
          <span css={footerText}>/</span>
          <span css={footerText}>{numPages}</span>
          {pageNumber === numPages ? (
            <div style={{ width: '30px' }} />
          ) : (
            <CircleButton
              icon={<ChevronRight />}
              size="30px"
              onClick={handleNextPage}
              backgroundColor="primary"
              color="background"
              padding="5px"
            />
          )}
        </FooterContainer>
      </div>
    </Modal>
  );
};

PdfViewerModal.defaultProps = {
  visible: false,
  tosUrl: null,
};

PdfViewerModal.propTypes = {
  visible: PropTypes.bool,
  tosUrl: PropTypes.string,
  onCloseModal: PropTypes.func.isRequired,
};

export default compose(
  withModal((props) => ({
    visible: props.visible,
  })),
  connect(mapStateToProps),
)(PdfViewerModal);

import styled from '@emotion/styled';
import { css } from '@emotion/core';

export const wrapperCss = css`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
`;

const lightenDarkenColorToRGBA = (col, amt, alpha) => {
  let newCol = col;

  if (col[0] === '#') {
    newCol = col.slice(1);
  }

  let r = parseInt(newCol.slice(0, 2), 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let g = parseInt(newCol.slice(2, 4), 16) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  let b = parseInt(newCol.slice(4, 6), 16) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  if (!alpha) {
    return `rgb(${r}, ${g}, ${b})`;
  }

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const getOverlayBackground = ({ background, theme }) => {
  if (!background) {
    return 'transparent';
  }

  const primaryColor = theme && theme.getColor('foreground');

  const lightColor = primaryColor
    ? lightenDarkenColorToRGBA(primaryColor, 40, 0.85)
    : 'rgba(54, 77, 179, 0.85)';

  const darkColor = primaryColor
    ? lightenDarkenColorToRGBA(primaryColor, 0, 0.85)
    : 'rgba(64, 7, 108, 0.85)';

  return `linear-gradient(
    -45deg,
    ${lightColor},
    ${darkColor}
  )`;
};

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 8px;
  background: ${getOverlayBackground};
`;

export const innerCss = css`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: 100px;
`;

export const innerWrapperCss = css`
  width: 100%;
  position: absolute;
  left: 50%;

  @media screen and (max-width: 580px) {
    width: 90%;
  }
`;

export const contentCss = css`
  background-color: white;
  border-radius: 10px;
  font-size: 14px;
  color: #737689;
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.5);
`;

export const sizes = {
  small: 375,
  medium: 535,
  mediumPlus: 620,
  large: 700,
};

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Loading from '../../components/LoadingBit';

import ScrollableView from '../../components/ScrollableView';
import { useInterval } from '../../utils/useInterval';

import {
  backgroundCss,
  wrapperCss,
  messageCss,
} from './OrderPaymentStatusPage.styles';

const OrderPaymentStatusRefresher = ({ onRefresh }) => {
  const { t } = useTranslation();

  useInterval(() => {
    if (onRefresh) {
      onRefresh();
    }
  }, 5000);

  return (
    <div css={backgroundCss}>
      <ScrollableView>
        <div css={wrapperCss}>
          <Loading
            show
            center
            style={{ height: 'auto', flexGrow: 0, margin: 20 }}
          />
          <div css={messageCss}>{t('orderPayment:processingTransaction')}</div>
        </div>
      </ScrollableView>
    </div>
  );
};

OrderPaymentStatusRefresher.defaultProps = {
  onRefresh: null,
};

OrderPaymentStatusRefresher.propTypes = {
  onRefresh: PropTypes.func,
};

export default OrderPaymentStatusRefresher;

import React from 'react';
import PropTypes from 'prop-types';
import CircleButton from '../CircleButton';

import CreditCardFlag from '../CreditCardFlag';
import deleteIcon from '../../assets/delete.svg';

import {
  ItemContainer,
  itemContentWrapper,
  cardNumberText,
} from './CreditCardItemSelect.styles';

const CreditCardItemSelect = ({
  cardNumber,
  brand,
  isSelected,
  onSelect,
  onRemove,
}) => {
  return (
    <ItemContainer selected={isSelected}>
      <div
        css={itemContentWrapper}
        type="button"
        onKeyDown={onSelect}
        onClick={onSelect}
        aria-label="cc-select"
        role="presentation"
      >
        <CreditCardFlag brand={brand} size={40} />
        <span css={cardNumberText}>{cardNumber}</span>
      </div>
      {!!onRemove && (
        <CircleButton
          src={deleteIcon}
          size="30px"
          onClick={onRemove}
          backgroundColor="error"
          padding="5px"
        />
      )}
    </ItemContainer>
  );
};

CreditCardItemSelect.defaultProps = {
  isSelected: false,
  brand: null,
  onRemove: null,
};

CreditCardItemSelect.propTypes = {
  cardNumber: PropTypes.string.isRequired,
  brand: PropTypes.string,
  isSelected: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
};

export default CreditCardItemSelect;

import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal';

import CancelOrderConfirmation from '../../components/CancelOrderConfirmation';
import localizePrice from '../../utils/localizePrice';
import withModal from '../../hocs/withModal';

const CancelOrderModal = ({ visible, onCloseModal, onConfirm, order }) => (
  <Modal visible={visible}>
    <CancelOrderConfirmation
      onConfirm={onConfirm}
      onCancel={onCloseModal}
      orderId={order.id}
      orderStoreName={order.store.name}
      orderPrice={`R$ ${localizePrice(order.priceTotal)}`}
    />
  </Modal>
);

CancelOrderModal.defaultProps = {
  visible: false,
};

CancelOrderModal.propTypes = {
  visible: PropTypes.bool,
  onCloseModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
};

export default withModal((props) => ({
  visible: props.visible,
}))(CancelOrderModal);

const colors = {
  default: '#727588',
  primary: '#07DCAC',
  secondary: '#FF4F65',
  purple: '#7D1DD1',
  yellow: '#d69608',
  blue: '#0090ff',
  orange: '#ff6f00',
  gray: '#d4d4d4',
  graytext: '#737689',
  eyeMobilePurple: '#9013fe',
  success: '#07DCAC',
  error: '#FF4F65',
  disabled: '#737689',
};

export default colors;

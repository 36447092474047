import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../components/Modal';

import withModal from '../../hocs/withModal';
import TagForm from '../../components/TagForm';
import { addressModalWrapper } from './OrderReviewPage.styles';

const OrderTagChangeModal = ({
  visible,
  initialTagValue,
  onFormSubmit,
  onCloseModal,
}) => {
  const initialValues = {
    tag: initialTagValue,
  };

  return (
    <Modal visible={visible}>
      <div css={addressModalWrapper}>
        <TagForm
          initialValues={initialValues}
          onFormSubmit={onFormSubmit}
          onCancel={onCloseModal}
        />
      </div>
    </Modal>
  );
};

OrderTagChangeModal.defaultProps = {
  visible: false,
};

OrderTagChangeModal.propTypes = {
  visible: PropTypes.bool,
  initialTagValue: PropTypes.string.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default withModal((props) => ({
  visible: props.visible,
}))(OrderTagChangeModal);

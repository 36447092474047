import React from 'react';
import ReactLoading from 'react-loading';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';

import LoadingContainer from './Loading.styles';

const Loading = ({ show, className, isSmall, center, loadingColor, style }) => {
  const theme = useTheme();

  const getColor = () => {
    if (loadingColor) return loadingColor;

    if (Object.prototype.hasOwnProperty.call(theme, 'getColor')) {
      return theme.getColor('primary');
    }

    return '#7d1dd1';
  };

  return (
    show && (
      <LoadingContainer style={style} className={className} center={center}>
        <ReactLoading
          type="spin"
          color={getColor()}
          height={isSmall ? 50 : 100}
          width={isSmall ? 50 : 100}
        />
      </LoadingContainer>
    )
  );
};

Loading.defaultProps = {
  center: false,
  className: '',
  isSmall: false,
  loadingColor: undefined,
  show: false,
  style: {},
};

Loading.propTypes = {
  center: PropTypes.bool,
  className: PropTypes.string,
  isSmall: PropTypes.bool,
  loadingColor: PropTypes.string,
  show: PropTypes.bool,
  style: PropTypes.object,
};

export default Loading;

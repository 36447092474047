import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import cardValidator from 'card-validator';
import Input from '../EyeInput';

import CreditCardFlag from '../CreditCardFlag';
import validCreditCardBrands from './validCreditCardBrands';
import {
  formMiniPadding,
  fieldPadding,
  rowFields,
  rowFieldPadding,
} from '../Form/Form.styles';
import {
  creditCardMask,
  creditCardExpirationMask,
  creditCardCvvMask,
} from '../../utils/masks';

const PaymentDataForm = ({
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
}) => {
  const { t } = useTranslation();

  const validation = cardValidator.number(values.creditCardNumber);

  const cardBrand = validation.card && validation.card.type;

  return (
    <div css={formMiniPadding}>
      <div css={fieldPadding}>
        <Input
          value={values.creditCardNumber}
          touched={touched.creditCardNumber}
          isValid={!errors.creditCardNumber}
          error={errors.creditCardNumber && t(errors.creditCardNumber)}
          placeholder={t('forms:creditCardNumber')}
          animateLabel
          mask={creditCardMask}
          name="creditCardNumber"
          onBlur={handleBlur}
          onChange={handleChange}
          inputMode="numeric"
          pattern="[0-9]*"
          guide={false}
          inputRightIcon={
            <CreditCardFlag
              brand={cardBrand && validCreditCardBrands[cardBrand]}
              size={30}
            />
          }
          variant="bordered"
          color="text"
          borderColor="text"
          labelColor="primary"
          labelBackgroundColor="background"
        />
      </div>
      <div css={fieldPadding}>
        <Input
          value={values.creditCardHolderName}
          touched={touched.creditCardHolderName}
          isValid={!errors.creditCardHolderName}
          error={errors.creditCardHolderName && t(errors.creditCardHolderName)}
          placeholder={t('forms:creditCardHolderName')}
          animateLabel
          name="creditCardHolderName"
          onBlur={handleBlur}
          onChange={handleChange}
          variant="bordered"
          color="text"
          borderColor="text"
          labelColor="primary"
          labelBackgroundColor="background"
        />
      </div>
      <div css={rowFields}>
        <div css={rowFieldPadding} style={{ maxWidth: '150px' }}>
          <Input
            value={values.creditCardExpiration}
            touched={touched.creditCardExpiration}
            isValid={!errors.creditCardExpiration}
            error={
              errors.creditCardExpiration && t(errors.creditCardExpiration)
            }
            placeholder={t('forms:creditCardExpiration')}
            animateLabel
            mask={creditCardExpirationMask}
            name="creditCardExpiration"
            onBlur={handleBlur}
            onChange={handleChange}
            inputMode="numeric"
            pattern="[0-9]*"
            variant="bordered"
            color="text"
            borderColor="text"
            labelColor="primary"
            labelBackgroundColor="background"
          />
        </div>
        <div css={rowFieldPadding} style={{ maxWidth: '120px' }}>
          <Input
            value={values.creditCardCvv}
            touched={touched.creditCardCvv}
            isValid={!errors.creditCardCvv}
            error={errors.creditCardCvv && t(errors.creditCardCvv)}
            placeholder={t('forms:creditCardCvv')}
            animateLabel
            mask={creditCardCvvMask}
            name="creditCardCvv"
            onBlur={handleBlur}
            onChange={handleChange}
            inputMode="numeric"
            pattern="[0-9]*"
            guide={false}
            variant="bordered"
            color="text"
            borderColor="text"
            labelColor="primary"
            labelBackgroundColor="background"
          />
        </div>
      </div>
    </div>
  );
};

PaymentDataForm.propTypes = {
  values: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default PaymentDataForm;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CircleButton from '../CircleButton';
import Button from '../ButtonBit';

import { ReactComponent as KeyboardArrowDown } from '../../assets/keyboard_arrow_down.svg';
import localizePrice from '../../utils/localizePrice';
import ProductReviewItem from '../ProductReviewItem';
import ComboReviewItem from '../ComboReviewItem';
import { smallMeasureTypesMap } from '../../utils/constants';

import {
  productList,
  menuTitle,
  clearButton,
  clearButtonText,
  productMenuHeader,
  bottomOptions,
  priceText,
  contentScroll,
  addItensButton,
  addItensButtonText,
  noProductText,
} from './OrderListMenu.styles';

const mapStateToProps = ({ tenantEyepass }) => ({
  tenantEyepass,
});

const OrderCartListMenu = ({
  products,
  totalPrice,
  onAddProduct,
  onRemoveProduct,
  onEditProduct,
  onClearCart,
  onClose,
  finishOrderDisabled,
  onFinishOrder,
  tenantEyepass,
}) => {
  const { t } = useTranslation();

  const { minCartValue } = tenantEyepass;

  const renderProductItem = (productItem) => {
    const {
      productOrderId,
      name,
      price,
      image,
      amount,
      measure,
      comboProductItems,
      complementGroups,
      complementsTotal,
      source,
    } = productItem;

    const fullPriceProduct =
      measure === 'UNIT'
        ? (price + complementsTotal) * amount
        : price * amount * 0.1 + complementsTotal;

    const productQuantityDescription =
      measure === 'UNIT'
        ? `${amount}`
        : `${amount * 100} ${smallMeasureTypesMap[measure]}`;

    if (comboProductItems && comboProductItems.length > 0) {
      return (
        <ComboReviewItem
          key={`combo-item-${productOrderId}`}
          comboImgSrc={image}
          comboName={name}
          quantity={amount}
          quantityDescription={productQuantityDescription}
          comboPrice={`R$ ${localizePrice(fullPriceProduct)}`}
          onRemove={() => onRemoveProduct(productItem)}
          onEdit={() => onEditProduct(productItem)}
          comboProductItems={comboProductItems}
        />
      );
    }

    return (
      <ProductReviewItem
        key={`item-${productOrderId}`}
        productImgSrc={image}
        productName={name}
        quantityDescription={productQuantityDescription}
        productPrice={`R$ ${localizePrice(fullPriceProduct)}`}
        onRemove={() => onRemoveProduct(productItem)}
        onEdit={
          source !== 'highlight' ? () => onEditProduct(productItem) : null
        }
        complementGroups={complementGroups}
      />
    );
  };

  return (
    <div css={productList}>
      <div css={productMenuHeader}>
        {products.length > 0 ? (
          <div
            css={clearButton}
            onClick={onClearCart}
            onKeyDown={onClearCart}
            role="presentation"
          >
            <span css={clearButtonText}>{t('productOrder:clearCart')}</span>
          </div>
        ) : (
          <div style={{ width: '50px' }} />
        )}
        <span css={menuTitle}>{t('productOrder:cart')}</span>
        <div style={{ width: '50px' }}>
          <CircleButton
            icon={<KeyboardArrowDown />}
            size="30px"
            onClick={onClose}
            backgroundColor="primary"
            color="background"
            padding="3px"
          />
        </div>
      </div>
      <div css={contentScroll}>
        {products.length === 0 ? (
          <>
            <div css={noProductText}>{t('productOrder:noProductsInCart')}</div>
            <div
              css={addItensButton}
              onClick={onAddProduct}
              onKeyDown={onAddProduct}
              role="presentation"
            >
              <span css={addItensButtonText}>{t('productOrder:addItems')}</span>
            </div>
          </>
        ) : (
          products.map(renderProductItem)
        )}
      </div>
      <div css={noProductText}>
        {t('productOrder:minimumOrder', {
          minValue: localizePrice(minCartValue),
        })}
      </div>
      <div css={bottomOptions}>
        <span css={priceText}>{`R$ ${localizePrice(totalPrice)}`}</span>
        <Button
          color="primary"
          disabled={finishOrderDisabled}
          onClick={onFinishOrder}
        >
          {t('productOrder:finishOrder')}
        </Button>
      </div>
    </div>
  );
};

OrderCartListMenu.defaultProps = {
  finishOrderDisabled: false,
};

OrderCartListMenu.propTypes = {
  products: PropTypes.array.isRequired,
  totalPrice: PropTypes.number.isRequired,
  finishOrderDisabled: PropTypes.bool,
  onAddProduct: PropTypes.func.isRequired,
  onRemoveProduct: PropTypes.func.isRequired,
  onEditProduct: PropTypes.func.isRequired,
  onClearCart: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onFinishOrder: PropTypes.func.isRequired,
  tenantEyepass: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(OrderCartListMenu);

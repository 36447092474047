import React from 'react';
import PropTypes from 'prop-types';

const checkSvgPath = 'M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z';

const closeSvgPath =
  'M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z';

const IconCircleOutline = ({ color, error }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d={error ? closeSvgPath : checkSvgPath} fill={color} />
  </svg>
);

IconCircleOutline.defaultProps = {
  color: '#ffffff',
  error: false,
};

IconCircleOutline.propTypes = {
  color: PropTypes.string,
  error: PropTypes.bool,
};

export default IconCircleOutline;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../components/ButtonBit';

import Logo from '../../components/Logo';
import ScrollableView from '../../components/ScrollableView';
import {
  backgroundCss,
  wrapperCss,
  flavourTextCss,
  buttonWrapperCss,
} from './HomePage.styles';

const HomePageScreen = ({
  flavourText,
  onFacebookLogin,
  onGoogleLogin,
  onRegistration,
}) => {
  const { t } = useTranslation();

  return (
    <div css={backgroundCss}>
      <ScrollableView>
        <div css={wrapperCss}>
          <Logo />
          <span css={flavourTextCss}>{`Olá, ${flavourText}`}</span>
          {onFacebookLogin && (
            <Button variant="outlined" color="uiText" onClick={onFacebookLogin}>
              {t('navigation:loginWithFacebook')}
            </Button>
          )}
          {onGoogleLogin && (
            <Button variant="outlined" color="uiText" onClick={onGoogleLogin}>
              {t('navigation:loginWithGoogle')}
            </Button>
          )}
          {onRegistration && (
            <div css={buttonWrapperCss}>
              <Button
                variant="outlined"
                color="uiText"
                onClick={onRegistration}
              >
                {t('navigation:continueToRegistration')}
              </Button>
            </div>
          )}
        </div>
      </ScrollableView>
    </div>
  );
};

HomePageScreen.defaultProps = {
  flavourText: '',
  onFacebookLogin: null,
  onGoogleLogin: null,
  onRegistration: null,
};

HomePageScreen.propTypes = {
  flavourText: PropTypes.string,
  onFacebookLogin: PropTypes.func,
  onGoogleLogin: PropTypes.func,
  onRegistration: PropTypes.func,
};

export default HomePageScreen;

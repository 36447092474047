import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CircleButton from '../CircleButton';

import { ReactComponent as KeyboardArrowDown } from '../../assets/keyboard_arrow_down.svg';
import { ReactComponent as QRCodeScanIcon } from '../../assets/qrcode_scan.svg';

import {
  HeaderContainer,
  qrCodeScanWrapper,
  userAddress,
  circleButtonStyle,
} from './OrderCartHeader.styles';

const mapStateToProps = ({ tenantEyepass: { qrReaderDisabled }, user }) => ({
  qrReaderDisabled,
  user,
});

const OrderCartHeader = ({
  deliveryAddress,
  onReadNewQrCode,
  onDeliveryAddressChange,
  promotionalRow,
  qrReaderDisabled,
  user,
}) => {
  const { t } = useTranslation();
  const qrCodeButtonRef = useRef(null);
  const addressText =
    deliveryAddress?.label || t('orderReview:addressNotRegistered');
  const hideHeader =
    (qrReaderDisabled ||
      (qrCodeButtonRef.current &&
        qrCodeButtonRef?.current?.style?.display === 'none')) &&
    !user?.id;

  return (
    <>
      <HeaderContainer style={{ display: hideHeader ? 'none' : 'flex' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {user?.id && (
            <>
              <span css={userAddress}>{addressText}</span>
              <CircleButton
                icon={<KeyboardArrowDown />}
                size="24px"
                backgroundColor="foreground"
                color="background"
                padding="3px"
                onClick={onDeliveryAddressChange}
                style={circleButtonStyle}
              />
            </>
          )}
        </div>

        <div />
        {!qrReaderDisabled && (
          <div
            css={qrCodeScanWrapper}
            id="qrcode-btn-wrapper"
            ref={qrCodeButtonRef}
          >
            <CircleButton
              icon={<QRCodeScanIcon />}
              size="40px"
              onClick={onReadNewQrCode}
              backgroundColor="background"
              padding="5px"
              color="foreground"
              style={circleButtonStyle}
            />
          </div>
        )}
      </HeaderContainer>
      {promotionalRow}
    </>
  );
};

OrderCartHeader.defaultProps = {
  onReadNewQrCode: null,
  onDeliveryAddressChange: null,
  promotionalRow: undefined,
  deliveryAddress: null,
  user: null,
};

OrderCartHeader.propTypes = {
  onReadNewQrCode: PropTypes.func,
  onDeliveryAddressChange: PropTypes.func,
  promotionalRow: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.func,
    PropTypes.bool,
  ]),
  deliveryAddress: PropTypes.object,
  qrReaderDisabled: PropTypes.bool.isRequired,
  user: PropTypes.object,
};

export default connect(mapStateToProps)(OrderCartHeader);

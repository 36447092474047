import styled from '@emotion/styled';
import { css } from '@emotion/core';
import facepaint from 'facepaint';
import colors from '../constants';

const mq = facepaint(['@media screen and (min-width: 375px)']);

export const TabsWrapper = styled.div`
  width: 100%;
`;

const tabHeaderWrapperBps = mq({
  height: [30, 50],
});

export const tabHeaderWrapper = css`
  ${tabHeaderWrapperBps};
  width: 100%;
  position: sticky;
  top: 0;
  overflow: hidden;
  z-index: 1000;
`;

export const tabsHeaders = (theme) => css`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: ${(theme &&
    theme.getColor &&
    theme.getColor('background')) ||
  '#ffffff'};
`;

const getFontSize = ({ theme }) => {
  if (Object.prototype.hasOwnProperty.call(theme, 'getFontSize')) {
    return mq({
      fontSize: [theme.getFontSize(0.8, 'px'), theme.getFontSize(1, 'px')],
    });
  }

  return mq({
    fontSize: [12, 16],
  });
};

export const TabHeader = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  text-align: center;
  height: 40px;
  text-transform: uppercase;
  user-select: none;
  padding-left: ${({ theme }) =>
    (theme && theme.getSpacing && theme.getSpacing(3, 'px')) || '24px'};
  padding-right: ${({ theme }) =>
    (theme && theme.getSpacing && theme.getSpacing(3, 'px')) || '24px'};
  cursor: pointer;
  color: ${({ theme, active }) =>
    active
      ? (theme && theme.getColor && theme.getColor('text')) || '#000000'
      : (theme && theme.getColor && theme.getColor('default')) ||
        colors.graytext};
  border-bottom: 2px solid
    ${({ theme, active }) =>
      active
        ? (theme && theme.getColor && theme.getColor('primary')) || '#000000'
        : 'transparent'};
  ${getFontSize};
  font-weight: bold;
  transition: background-color, color 0.5s ease-in-out;
`;

export const TabsPanels = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const TabPanel = styled.div`
  width: 100%;
`;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Modal from '../../components/Modal';
import CircleButton from '../../components/CircleButton';

import { ReactComponent as KeyboardArrowDown } from '../../assets/keyboard_arrow_down.svg';
import CreditCardForm from '../../components/CreditCardForm';
import withModal from '../../hocs/withModal';
import {
  productContent,
  productInnerWrapper,
  productOverlay,
  container,
  menuHeader,
  menuTitle,
  contentScroll,
} from '../../components/ProductAdjustModal/ProductAdjustModal.styles';

const AddCreditCardModal = ({ visible, onClose, onFinish }) => {
  const { t } = useTranslation();

  return (
    <Modal
      visible={visible}
      styles={{
        innerWrapper: productInnerWrapper,
        content: productContent,
        overlay: productOverlay,
      }}
      keepMounted
    >
      <div css={container}>
        <div css={menuHeader}>
          <div style={{ width: '30px' }} />
          <span css={menuTitle}>{t('orderReview:addCreditCard')}</span>
          <CircleButton
            icon={<KeyboardArrowDown />}
            size="30px"
            onClick={onClose}
            backgroundColor="primary"
            color="background"
            padding="3px"
          />
        </div>
        <div css={contentScroll}>
          <CreditCardForm onFormSubmit={onFinish} />
        </div>
      </div>
    </Modal>
  );
};

AddCreditCardModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default withModal((props) => ({
  visible: props.visible,
}))(AddCreditCardModal);

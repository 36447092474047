import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Loading from '../LoadingBit';
import Button from '../ButtonBit';

import { menuWrapper, buttonsRow, labelText } from './FirstStoreSelect.styles';

const FirstStoreSelect = ({ storeData, onAccept, onDeny, isLoading }) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <Loading show center style={{ height: 'auto', flexGrow: 0 }} />;
  }

  if (!storeData) {
    return (
      <div css={menuWrapper}>
        <span css={labelText}>{t('productOrder:noStoreFound')}</span>
        <div css={buttonsRow}>
          <Button color="primary" onClick={onDeny}>
            {t('context:ok')}
          </Button>
        </div>
      </div>
    );
  }

  const { name } = storeData;

  return (
    <div css={menuWrapper}>
      <span css={labelText}>{`${t('context:yourStoreIs')} ${name}?`}</span>
      <div css={buttonsRow}>
        <Button color="error" onClick={onDeny}>
          {t('context:no')}
        </Button>
        <Button color="success" onClick={onAccept}>
          {t('context:yes')}
        </Button>
      </div>
    </div>
  );
};

FirstStoreSelect.defaultProps = {
  isLoading: false,
};

FirstStoreSelect.propTypes = {
  storeData: PropTypes.object.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDeny: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default FirstStoreSelect;
